import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const AttachmentButtonWrapper = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: ${spacing(1)};
    align-items: center;
`
export const AttachmentButton = styled.button`
    border: none;
    background: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: ${(props) => props.theme.colors.link.primary};
    padding: 0;
    margin: 0;

    svg {
        margin-right: ${spacing(1)};
    }
`
