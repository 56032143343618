import createCachedSelector from 're-reselect'
import {rolesSelector} from '../../store/state/roles/selectors'
import {Role} from '../../store/state/roles/state'
import AppState from '../../store/types/app-state'

export const isUserAllowedCachedReselector = createCachedSelector(
    [rolesSelector, (_state: AppState, rolesAllowed: Role[]) => rolesAllowed],
    (userRoles, rolesAllowed) => {
        return rolesAllowed.reduce((acc, roleAllowed) => {
            if (acc) {
                return acc
            }
            return userRoles.find((userRole) => userRole.role === roleAllowed) != undefined
        }, false)
    },
)((_state, rolesAllowed) => rolesAllowed.reduce((acc, roleAllowed) => `${acc}-${roleAllowed}`, ''))
