import {Redirect, Route, Switch} from 'react-router-dom'
import {MedullaReports} from '../pages/medulla-reports/medulla-reports'

export function AuthenticatedRoutes(): JSX.Element {
    return (
        <>
            <Switch>
                <Route path="/reports" component={MedullaReports} />
                <Route path="/" render={() => <Redirect to="/reports" />} />
            </Switch>
        </>
    )
}
