import styled from 'styled-components'

export const Button = styled.button`
    height: 19px;
    width: 19px;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
`
