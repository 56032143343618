enum HeaderItemsScale {
    BREAKPOINT_5 = 0.4,
    BREAKPOINT_4 = 0.5,
    BREAKPOINT_3 = 0.65,
    BREAKPOINT_2 = 0.75,
    BREAKPOINT_1 = 1,
}
export function getScale(width: number): number {
    return width > 1080
        ? HeaderItemsScale.BREAKPOINT_1
        : width > 440
        ? HeaderItemsScale.BREAKPOINT_2
        : width > 400
        ? HeaderItemsScale.BREAKPOINT_3
        : width > 350
        ? HeaderItemsScale.BREAKPOINT_4
        : HeaderItemsScale.BREAKPOINT_5
}
