import styled from 'styled-components'

export const Button = styled.button`
    display: flex;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    background-color: ${(props) => props.theme.pagingButton.clickable.default.background};
    color: ${(props) => props.theme.pagingButton.clickable.default.text};
    border: 1px solid ${(props) => props.theme.pagingButton.clickable.default.border};
    border-radius: 4px;
    height: 27px;
    width: 27px;
    align-items: center;
    justify-content: center;
    margin: 0 5px;

    &:hover {
        border-color: ${(props) => props.theme.pagingButton.clickable.hover.border};
        color: ${(props) => props.theme.pagingButton.clickable.hover.text};
        background-color: ${(props) => props.theme.pagingButton.clickable.hover.background};
    }
    &:active {
        border-color: ${(props) => props.theme.pagingButton.clickable.active.border};
        color: ${(props) => props.theme.pagingButton.clickable.active.text};
        background-color: ${(props) => props.theme.pagingButton.clickable.active.background};
    }
`
