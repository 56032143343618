import ActionType from './action-type'
import {Action} from './actions'

import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'

import {UsersReduxState, DEFAULT_USERS_STATE, UserData} from './state'
import LoadingState from '../../../values/loading-state-enum'

function setLoading(currentState: UsersReduxState): UsersReduxState {
    if (currentState.loadingState === LoadingState.RequestingData) {
        return currentState
    }

    return {
        ...currentState,
        loadingState: LoadingState.RequestingData,
    }
}

function recordNeedsUpdating(existingRecord: UserData, newRecord: UserData): boolean {
    return (
        existingRecord.Username !== newRecord.Username || existingRecord.Email !== newRecord.Email
    )
}

function updateUserArray(
    existingUsers: UserData[],
    receivedUsers: UserData[],
    keepOld = false,
): {mappedData: UserData[]; changesApplied: boolean} {
    const newRecords: UserData[] = []
    const updatedRecords: UserData[] = []
    const unchangedRecords: UserData[] = []
    let changesApplied = false

    receivedUsers.forEach((record) => {
        const existingRecord = existingUsers?.find((user) => user.Identity === record.Identity)

        if (!existingRecord) {
            changesApplied = true
            newRecords.push(record)
        } else {
            if (recordNeedsUpdating(existingRecord, record)) {
                changesApplied = true
                updatedRecords.push(record)
            } else {
                unchangedRecords.push(existingRecord)
            }
        }
    })

    const noNewData = newRecords.length === 0 && updatedRecords.length === 0

    let noRecordsToRemove = true

    if (!keepOld) {
        noRecordsToRemove = unchangedRecords.length === existingUsers?.length
    }

    if (noNewData && noRecordsToRemove) {
        return {mappedData: existingUsers, changesApplied: false}
    }

    const mappedData: UserData[] = []

    newRecords.forEach((record) => {
        changesApplied = true
        mappedData.push(record)
    })

    updatedRecords.forEach((record) => {
        changesApplied = true
        mappedData.push(record)
    })

    unchangedRecords.forEach((record) => {
        mappedData.push(record)
    })

    if (keepOld) {
        existingUsers.forEach((record) => {
            if (!mappedData?.find((user) => user.Identity === record.Identity)) {
                mappedData.push(record)
            }
        })
    } else {
        changesApplied = true
    }

    return {mappedData, changesApplied}
}

function setUsers(currentState: UsersReduxState, receivedData: UserData[]): UsersReduxState {
    const {mappedData, changesApplied} = updateUserArray(currentState.users, receivedData)

    if (!changesApplied) {
        if (currentState.loadingState === LoadingState.Loaded) {
            return currentState
        }

        return {
            ...currentState,
            loadingState: LoadingState.Loaded,
        }
    }

    return {
        ...currentState,
        loadingState: LoadingState.Loaded,
        users: mappedData,
    }
}

function logout(currentState: UsersReduxState): UsersReduxState {
    if (
        currentState.loadingState === LoadingState.NotPopulated &&
        currentState.users &&
        currentState.users.length === 0
    ) {
        return currentState
    }

    return DEFAULT_USERS_STATE
}

const UsersReducer = (
    state: UsersReduxState = DEFAULT_USERS_STATE,
    action: Action | LoggingOutAction,
): UsersReduxState => {
    switch (action.type) {
        case ActionType.REQUEST_USERS:
            return setLoading(state)

        case ActionType.SET_USERS:
            return setUsers(state, action.payload)

        case SessionActionType.LOGGING_OUT:
            return logout(state)

        default:
            return state
    }
}

export default UsersReducer
