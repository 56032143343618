import {clamp} from 'lodash'
import moment, {Moment} from 'moment'

import {DEFAULT_DATE_FORMAT, DEFAULT_DATE_FORMAT_WITHOUT_TIME} from './consts'

export const normaliseScore = (score: number, lowerBound = 0, upperBound = 99): number => {
    return Math.round(clamp(isNaN(score) ? 0 : score, lowerBound, upperBound))
}

export const formatDate = (date: string | Moment): string => {
    return date && moment(date).isValid() ? moment(date).format(DEFAULT_DATE_FORMAT) : 'N/A'
}

export const formatDateWithoutTime = (date: string | Moment): string => {
    return date && moment(date).isValid()
        ? moment(date).format(DEFAULT_DATE_FORMAT_WITHOUT_TIME)
        : 'N/A'
}

export const formatNumber = (num: number): string => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
