import {CustomerReports} from '../../../../values/customer-reports'
import {ActionType} from './action-type'
import * as Actions from './actions'
import {LocationIdType} from '../../../../store/state/locations/state'
import {GuidType} from '../../../../values/generic-type-defintions'

export function setSelectedPage(newSelectedPage: number): Actions.SetSelectedPageAction {
    return {type: ActionType.SET_SELECTED_PAGE, payload: newSelectedPage}
}
export function changePageSize(newPageSize: number): Actions.ChangePageSizeAction {
    return {type: ActionType.CHANGE_PAGE_SIZE_ACTION, payload: newPageSize}
}

export function requestInitialPageData(): Actions.RequestInitialPageDataAction {
    return {type: ActionType.REQUEST_INITIAL_PAGE_DATA_ACTION}
}

export function switchToCachedPageData(requestedPage: number): Actions.SwitchToCachedPagedAction {
    return {type: ActionType.SWITCH_TO_CACHED_PAGE_DATA_ACTION, payload: requestedPage}
}

export function requestPageData(requestedPage: number): Actions.RequestPageDataAction {
    return {type: ActionType.REQUEST_PAGE_DATA_ACTION, payload: requestedPage}
}
export function receivedRequestedPageData(
    items: CustomerReports[],
    total: number,
    earliestPeriodEndDate: string,
    latestPeriodEndDate: string,
    allReportTypes: string[],
): Actions.ReceivedRequestedPageDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_PAGE_DATA_ACTION,
        payload: {items, total, earliestPeriodEndDate, latestPeriodEndDate, allReportTypes},
    }
}
export function resetCustomerReports(): Actions.ResetCustomerReportsAction {
    return {
        type: ActionType.RESET_CUSTOMER_REPORTS,
    }
}

export function setFilter(
    locations: Set<LocationIdType> | undefined,
    rating: number,
    timeRangeFrom: string | undefined,
    timeRangeTo: string | undefined,
    selectedReportType: string | undefined,
    basalGangliaEnvironments: GuidType | undefined,
    includeWholeFleetReports: boolean,
): Actions.SetFilterAction {
    return {
        type: ActionType.SET_FILTER,
        payload: {
            locations,
            rating,
            timeRangeFrom,
            timeRangeTo,
            selectedReportType,
            basalGangliaEnvironments,
            includeWholeFleetReports,
        },
    }
}
