import {useToggle} from '../../../../contexts/toggle/use-toggle'
import {IndicatorDot} from './_styled/nav-bar-content.styled'
import {NavigationLinkStyle} from './_styled/nav-bar-content.styled'

interface NavBarLinkProps {
    id: string
    path: string
    navLinkText: string
    showIndicator?: boolean
    gtmLogger: (() => void) | null
}

export function NavBarLink({
    id,
    path,
    navLinkText,
    showIndicator,
    gtmLogger = null,
}: NavBarLinkProps): JSX.Element {
    const {setValue: setMenuOpen} = useToggle()
    function menuClicked() {
        if (gtmLogger) {
            gtmLogger()
        }
        setMenuOpen(false)
    }

    return (
        <NavigationLinkStyle id={id} to={path} activeClassName="activeLink" onClick={menuClicked}>
            {navLinkText}
            {showIndicator && <IndicatorDot />}
        </NavigationLinkStyle>
    )
}
