import {GuidType} from '../../../values/generic-type-defintions'
import {LocationIdType} from '../locations/state'
import ActionType from './action-type'
import * as Actions from './actions'

export function setSelectedRating(selectedRating: number): Actions.SetSelectedRating {
    return {
        type: ActionType.SET_SELECTED_RATING,
        payload: selectedRating,
    }
}

export function setSelectedFromTimeRange(selectedTimeRange: string): Actions.SetSelectedTimeRange {
    return {
        type: ActionType.SET_SELECTED_TIME_RANGE_FROM,
        payload: selectedTimeRange,
    }
}

export function setSelectedToTimeRange(selectedTimeRange: string): Actions.SetSelectedTimeRange {
    return {
        type: ActionType.SET_SELECTED_TIME_RANGE_TO,
        payload: selectedTimeRange,
    }
}
export function setSelectedLocation(location: Set<LocationIdType>): Actions.SetSelectedLocation {
    return {
        type: ActionType.SET_LOCATION,
        payload: location,
    }
}
export function toggleAllVessels(selected: boolean): Actions.ToggleAllVessels {
    return {
        type: ActionType.TOGGLE_ALL_VESSELS,
        payload: selected,
    }
}

export function toggleVessel(
    allLocations: Array<LocationIdType>,
    location: LocationIdType,
    newValue: boolean,
): Actions.ToggleVessel {
    return {
        type: ActionType.TOGGLE_VESSEL,
        payload: {
            allLocations,
            location,
            newValue,
        },
    }
}

export function setSelectedReportType(value: string | undefined): Actions.SetSelectedReportType {
    return {
        type: ActionType.SET_SELECTED_REPORT_TYPE,
        payload: value,
    }
}
export function setSelectedBasalGangliaEnv(
    value: GuidType | undefined,
): Actions.SetSelectedBasalGangliaEnv {
    return {
        type: ActionType.SET_SELECTED_BASAL_GANGLIA_ENV,
        payload: value,
    }
}
export function resetFilter(): Actions.ResetFilter {
    return {type: ActionType.RESET_FILTER}
}
export function toggleWholeFleetReports(value: boolean): Actions.ToggleWholeFleetReports {
    return {
        type: ActionType.TOGGLE_WHOLE_FLEET_REPORTS,
        payload: value,
    }
}
