import {createSelector} from 'reselect'
import {basalGangliaEnvLoadingSelector} from '../../../store/state/basal-ganglia-env/selectors'
import {fetchingStatusSelector} from '../../../store/state/config/selectors'
import {ConfigFetchingStatus} from '../../../store/state/config/state'
import {locationsLoadingSelector} from '../../../store/state/locations/selectors'
import {isFetchingRolesSelector} from '../../../store/state/roles/selectors'

export const allDataIsLoadedReselector = createSelector(
    isFetchingRolesSelector,
    locationsLoadingSelector,
    basalGangliaEnvLoadingSelector,
    fetchingStatusSelector,
    (rolesLoading, locationsLoading, basalGangliaEnvLoading, configFetchingStatus): boolean => {
        return rolesLoading ||
            locationsLoading ||
            basalGangliaEnvLoading ||
            configFetchingStatus !== ConfigFetchingStatus.INITIALISED
            ? false
            : true
    },
)
