import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {showFilterBarOnScreen} from '../data-helpers'

export const FilterBarStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: ${(props) => props.theme.colors.background.default};
    color: ${(props) => props.theme.colors.text.default};
    min-height: 250px;
    box-shadow: ${(props) => props.theme.reports.filterBar.boxShadow};
`
interface SectionContentProps {
    width: number
}
export const SectionContent = styled.div<SectionContentProps>`
    overflow-y: auto;
    padding: ${spacing(3)} ${spacing(4)};
    margin: 0;
    width: 100%;
    border-bottom: ${(props) =>
        showFilterBarOnScreen(props.width)
            ? `1px solid ${props.theme.colors.border.lowContrast}`
            : 'none'};
`
export const SectionVessels = styled.div`
    overflow-y: auto;
    padding: ${spacing(3)} ${spacing(4)};
    margin: 0;
    width: 100%;
`

export const Loading = styled.div`
    padding: 0;
    height: 400px;
    max-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.background.default};
    box-shadow: ${(props) => props.theme.reports.filterBar.boxShadow};
`
export const ButtonsArea = styled.div`
    display: flex;
    justify-content: flex-end;
    column-gap: ${spacing(2)};
    align-items: center;
    padding: 0;
`
