import styled from 'styled-components'

export const FooterWrapper = styled.div`
    display: flex;
    flex-direction: column;
`
export const ActionsFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.28px;
    color: ${(props) => props.theme.login.success.actionsFooter};
`
