type getRatingStarsProps = 1 | 2 | 3 | 4 | 5

export function getRatingStars(ratingValue: number): getRatingStarsProps {
    if (ratingValue <= 10) {
        return 1
    } else if (ratingValue <= 33) {
        return 2
    } else if (ratingValue <= 66) {
        return 3
    } else if (ratingValue <= 90) {
        return 4
    } else return 5
}
