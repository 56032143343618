import styled from 'styled-components'

export const Paging = styled.div`
    display: flex;
    font-size: 12px;
    line-height: 17px;
    justify-content: flex-start;
    flex: 1;
    margin-left: -5px;
    margin-right: -5px;
`
