import * as Styled from './styles/actions-footer.styled'
import * as StyledAdmin from './styles/admin-sign-in-button.styled'
import {AdminSignInButton} from './admin-sign-in-button'
import {Cargo} from './styles/cargo'
import {AdminProps} from '../../login-page'
import {clickAdminSignInEvent} from '../../../../core/google-tag-manager/login/click-admin-sign-in-event'

interface ActionFooterProps extends AdminProps {
    inRowDisplay?: boolean
}

export function ActionsFooter(props: ActionFooterProps): JSX.Element {
    const appVersion = `V ${process.env.REACT_APP_SHIPOWNERS_VERSION}${
        process.env.REACT_APP_BUILD_NUMBER ? `+${process.env.REACT_APP_BUILD_NUMBER}` : ''
    }`

    if (props.admin) {
        return (
            <Styled.ActionsFooter>
                {props.inRowDisplay && <Cargo scale={0.9} />}
                <AdminSignInButton />
            </Styled.ActionsFooter>
        )
    }

    return (
        <Styled.ActionsFooter>
            <span id="app-version">{appVersion}</span>
            {props.inRowDisplay && <Cargo scale={0.9} />}
            <StyledAdmin.AdminSignInButton
                id="admin-signin"
                target="_self"
                href="adminLogin"
                onClick={() => clickAdminSignInEvent()}
            >
                Admin Sign In
            </StyledAdmin.AdminSignInButton>
        </Styled.ActionsFooter>
    )
}
