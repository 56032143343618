import ActionType from './action-type'
import * as Actions from './actions'
import {ErrorOccurred} from '../session-data/action-creators'
import {AnyAction} from 'redux'
import {ThunkAction} from 'redux-thunk'
import {UserAgentApplication} from 'msal'

import {Api} from '../../../api/Api'
import {fetchRolesForCurrentUser} from '../roles/action-creators'
import AppState from '../../types/app-state'
import {fetchCurrentUser} from '../current-user/action-creators'
import {fetchLocations} from '../locations/action-creators'
import {fetchBasalGangliaEnv} from '../basal-ganglia-env/action-creators'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logout(msal?: UserAgentApplication): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (dispatch: any): void => {
        if (msal) {
            try {
                msal.logout()
                dispatch(clearMSAL())
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err)
            }
        }

        dispatch(clearAuthToken())
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function errorOccured(error: string): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (dispatch: any): void => {
        dispatch(ErrorOccurred(error))
    }
}

export function authTokenRetrieved(authToken: string): Actions.AuthTokenRetrievedAction {
    return {
        type: ActionType.RECEIVE_AUTH_TOKEN,
        payload: authToken,
    }
}

export function clearAuthToken(): Actions.ClearAuthTokenAction {
    return {
        type: ActionType.CLEAR_AUTH_TOKEN,
    }
}

export function fetchInitialData(): ThunkAction<void, AppState, Api, AnyAction> {
    return (dispatch) => {
        dispatch(fetchRolesForCurrentUser())
        dispatch(fetchCurrentUser())
        dispatch(fetchLocations())
        dispatch(fetchBasalGangliaEnv())
    }
}

export function setMSAL(msal: UserAgentApplication): Actions.SetMSALAction {
    return {
        type: ActionType.SET_MSAL,
        payload: msal,
    }
}

export function clearMSAL(): Actions.ClearMSALAction {
    return {
        type: ActionType.CLEAR_MSAL,
    }
}
