import {Fragment} from 'react'
import {viewReportsEvent} from '../../../../core/google-tag-manager/navigation/view-reports-event'
import {NavBarLink} from './nav-bar-link'

export function NavBarContent(): JSX.Element {
    return (
        <Fragment>
            <NavBarLink
                id="navigation-reports"
                path="/reports"
                navLinkText="Reports"
                gtmLogger={() => viewReportsEvent('hamburgerMenu', 'v1')}
            />
        </Fragment>
    )
}
