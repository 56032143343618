import ActionType from './action-type'
import {Action} from './actions'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import {CurrentUserReduxState, DEFAULT_CURRENT_USER_STATE} from './state'
import {isEqual} from 'lodash'
import produce from 'immer'

const currentUserReducer = produce(
    (
        draft: CurrentUserReduxState = DEFAULT_CURRENT_USER_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_CURRENT_USER:
                draft.isFetching = true
                break
            case ActionType.RECEIVE_CURRENT_USER:
                draft.isFetching = false
                if (!isEqual(draft.currentUser, action.payload)) {
                    draft.currentUser = action.payload
                }
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_CURRENT_USER_STATE
                break
            /* istanbul ignore next */
            default:
                return draft
        }
        return draft
    },
)
export default currentUserReducer
