import {Dispatch, useContext} from 'react'
import {PagedReportsContext} from './paged-reports-context'
import {UsePagedReportsResult} from './use-paged-reports-output'
import * as ActionCreators from './state/action-creators'
import {AllActions} from './state/actions'
import {REST} from '../../..'
import {CustomerReportAttachmentData} from '../../../values/customer-reports'
import downloadFile from '../../../helpers/downloadFile'
import {getFormattedSelectedVessels} from '../components/data-helpers'
import {LocationIdType} from '../../../store/state/locations/state'
import {warn} from '../../../helpers/logging'
import {GuidType} from '../../../values/generic-type-defintions'

const REPORTS_ENDPOINT = '/api/v1/customerReports'

function getData(
    dispatch: Dispatch<AllActions>,
    showLoader = true,
    pageSize: number,
    timeRangeFrom: string | undefined,
    locations: Set<LocationIdType> | undefined,
    offset: number,
    rating: number,
    selectedReportType: string | undefined,
    timeRangeTo: string | undefined,
    basalGangliaEnvironments: GuidType | undefined,
    includeWholeFleet: boolean,
): void {
    if (showLoader) {
        dispatch(ActionCreators.requestInitialPageData())
    }
    REST.post(`${REPORTS_ENDPOINT}/find`, {
        from: timeRangeFrom ? timeRangeFrom : undefined,
        to: timeRangeTo ? timeRangeTo : undefined,
        pagination: {offset: offset, count: pageSize},
        rating: rating,
        locations: locations ? getFormattedSelectedVessels(locations) : undefined,
        reportType: selectedReportType ? selectedReportType : undefined,
        basalGangliaEnvironments: basalGangliaEnvironments ? [basalGangliaEnvironments] : undefined,
        includeWholeFleet: includeWholeFleet,
    }).then((response) => {
        dispatch(
            ActionCreators.receivedRequestedPageData(
                response.data.data,
                response.data.totalNumberOfReports || 0,
                response.data.earliestPeriodEndDate,
                response.data.latestPeriodEndDate,
                response.data.allReportTypes,
            ),
        )
    })
}

function buildDownloadUrl(
    reportIdentity: GuidType,
    attachment: CustomerReportAttachmentData,
    basalGangliaEnvironmentIdentity: GuidType,
): string {
    return `${REPORTS_ENDPOINT}/${reportIdentity}/files/${attachment.identity}?basalGangliaEnvironment=${basalGangliaEnvironmentIdentity}`
}
function getAttachmentCustomerReports(
    _dispatch: Dispatch<AllActions>,
    reportIdentity: GuidType,
    attachment: CustomerReportAttachmentData,
    basalGangliaEnvironmentIdentity: GuidType,
): void {
    REST.get(buildDownloadUrl(reportIdentity, attachment, basalGangliaEnvironmentIdentity), {
        responseType: 'blob',
    })
        .then((resp) => resp.data.arrayBuffer())
        .then((responseArray) => {
            if (responseArray) {
                const blob = new Blob([responseArray], {type: attachment.contentType})
                downloadFile(blob, attachment.name)
            }
        })
}

export function usePagedReports(): UsePagedReportsResult {
    const {state, dispatch} = useContext(PagedReportsContext)

    if (state == undefined || dispatch == undefined) {
        throw new Error('usePagedReports must be used within a PagedReportsContext')
    }

    function setPageSize(pageSize: number): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        dispatch(ActionCreators.changePageSize(pageSize))
        getData(
            dispatch,
            false,
            pageSize,
            state.timeRangeFrom,
            state.locations,
            0,
            state.rating,
            state.selectedReportType,
            state.timeRangeTo,
            state.basalGangliaEnvironments,
            state.includeWholeFleetReports,
        )
    }
    function loadInitialPage(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        dispatch(ActionCreators.requestInitialPageData())
        getData(
            dispatch,
            false,
            state.pageSize,
            state.timeRangeFrom,
            state.locations,
            0,
            state.rating,
            state.selectedReportType,
            state.timeRangeTo,
            state.basalGangliaEnvironments,
            state.includeWholeFleetReports,
        )
    }
    function selectPage(requestedPage: number | undefined): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        if (requestedPage == undefined) {
            warn('requestedPage is not defined')
            return
        }

        if (state.dataReportsMap?.has(requestedPage)) {
            dispatch(ActionCreators.switchToCachedPageData(requestedPage))
            return
        }

        const offset = requestedPage * state.pageSize
        dispatch(ActionCreators.requestPageData(requestedPage))
        getData(
            dispatch,
            false,
            state.pageSize,
            state.timeRangeFrom,
            state.locations,
            offset,
            state.rating,
            state.selectedReportType,
            state.timeRangeTo,
            state.basalGangliaEnvironments,
            state.includeWholeFleetReports,
        )
    }

    function downloadCustomerReports(
        reportIdentity: GuidType,
        attachment: CustomerReportAttachmentData,
        basalGangliaEnvironmentIdentity: GuidType,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        getAttachmentCustomerReports(
            dispatch,
            reportIdentity,
            attachment,
            basalGangliaEnvironmentIdentity,
        )
    }

    function setFilter(
        locations: Set<LocationIdType> | undefined,
        rating: number,
        timeRangeFrom: string | undefined,
        timeRangeTo: string | undefined,
        selectedReportType: string | undefined,
        basalGangliaEnvironments: GuidType | undefined,
        includeWholeFleetReports: boolean,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        dispatch(
            ActionCreators.setFilter(
                locations,
                rating,
                timeRangeFrom,
                timeRangeTo,
                selectedReportType,
                basalGangliaEnvironments,
                includeWholeFleetReports,
            ),
        )
        getData(
            dispatch,
            false,
            state.pageSize,
            timeRangeFrom ? timeRangeFrom : undefined,
            locations ? locations : undefined,
            0,
            rating ? rating : 101,
            selectedReportType ? selectedReportType : '',
            timeRangeTo ? timeRangeTo : undefined,
            basalGangliaEnvironments ? basalGangliaEnvironments : undefined,
            includeWholeFleetReports,
        )
    }

    const hasData = state.totalNumberOfReports != undefined
    const dataReports = hasData
        ? state.dataReportsMap?.get(state.selectedPage || 0) ?? undefined
        : undefined

    return {
        loading: state.loading,
        pageSize: state.pageSize,
        dataReports: dataReports,
        rating: state.rating,
        timeRangeFrom: state.timeRangeFrom,
        timeRangeTo: state.timeRangeTo,
        selectedReportType: state.selectedReportType,
        totalNumberOfReports: state.totalNumberOfReports,
        selectedPage: state.selectedPage,
        totalNumberOfPages: state.totalNumberOfPages,
        setPageSize,
        refreshData: loadInitialPage,
        selectPage: hasData ? selectPage : null,
        downloadCustomerReports,
        locations: state.locations,
        earliestPeriodEndDate: state.earliestPeriodEndDate,
        latestPeriodEndDate: state.latestPeriodEndDate,
        allReportTypes: state.allReportTypes,
        basalGangliaEnvironments: state.basalGangliaEnvironments,
        setFilter,
        // ...useMemo(() => buildActionCreators(dispatch), [dispatch]),
    }
}
