import {ReactNodeArray} from 'react'
import {ReactNode} from 'react'
import {Navigation} from '../../components/Navigation'
import {ScrollablePage} from './scrollable-page.styled'

interface Props {
    onClick?: () => void
    children: ReactNode | ReactNodeArray
}

export function ScrollablePageTemplate({children, onClick}: Props): JSX.Element {
    if (onClick) {
        return (
            <ScrollablePage onClick={onClick}>
                <Navigation />
                {children}
            </ScrollablePage>
        )
    }

    return (
        <ScrollablePage>
            <Navigation />
            {children}
        </ScrollablePage>
    )
}
