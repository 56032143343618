import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {FilterButton} from './apply-filter/apply-filter-button'
import {ComplianceRating} from './compliance-rating/compliance-rating'
import * as Styles from './filter-bar.styled'
import {FilterBarHeader} from './header/filter-bar-header'
import {ReportType} from './report-type/report-type'
import {ResetFilterNarrowButton} from './reset-filter/reset-filter-button-narrow'
import {ShipManager} from './ship-manager/ship-manager'
import {TimePeriodFrom} from './time-period/time-period-from'
import {TimePeriodTo} from './time-period/time-period-to'
import {VesselsFilterNarrow} from './vessel-filter/vessels-filter-narrow'
import {WholeFleetReports} from './whole-fleet-reports/whole-fleet-reports'

export function FilterBarNarrow(): JSX.Element {
    const {width} = useDimensions()

    return (
        <Styles.FilterBarStyled>
            <FilterBarHeader />
            <Styles.SectionContent width={width}>
                <ReportType />
                <ShipManager />
                <ComplianceRating />
                <TimePeriodFrom />
                <TimePeriodTo />
                <WholeFleetReports />
                <VesselsFilterNarrow />
                <Styles.ButtonsArea>
                    <FilterButton />
                    <ResetFilterNarrowButton />
                </Styles.ButtonsArea>
            </Styles.SectionContent>
        </Styles.FilterBarStyled>
    )
}
