import authReducer from './state/auth/reducer'
import rolesReducer from './state/roles/reducer'
import configReducer from './state/config/reducer'
import UsersReducer from './state/users/reducer'
import sessionDataReducer from './state/session-data/reducer'
import currentUserReducer from './state/current-user/reducer'
import LocationsReducer from './state/locations/reducer'
import {reportsFiltersReducer} from './state/report-filters/reducer'
import basalGangliaEnvReducer from './state/basal-ganglia-env/reducer'

export const staticReducers = {
    auth: authReducer,
    config: configReducer,
    roles: rolesReducer,
    users: UsersReducer,
    sessionData: sessionDataReducer,
    currentUser: currentUserReducer,
    locations: LocationsReducer,
    reportsFilter: reportsFiltersReducer,
    basalGangliaEnv: basalGangliaEnvReducer,
}

export default staticReducers
