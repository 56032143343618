import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const NarrowButton = styled.button`
    border: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.reports.narrowButton.default.textColor};
    background-color: ${(props) => props.theme.reports.narrowButton.default.backgroundColor};
    border: 1px solid;
    border-radius: 6px;
    padding: ${spacing(1)};
    width: 82px;
    height: 26px;
    &:hover {
        color: ${(props) => props.theme.reports.narrowButton.hover.textColor};
        background-color: ${(props) => props.theme.reports.narrowButton.hover.backgroundColor};
    }
`
export const Button = styled.button`
    border: none;
    background-color: ${(props) => props.theme.reports.resetButton.background};
    color: ${(props) => props.theme.reports.resetButton.text};
    border-radius: 3px;
    margin: ${spacing(1)} auto ${spacing(3)} auto;
    padding: ${spacing(1)} ${spacing(2)};
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
