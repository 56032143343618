import styled from 'styled-components'
import {spacing} from '../../theme/spacing'
import {MAX_SCREEN_WIDTH} from '../../theme/theme'

export const NavigationBar = styled.nav`
    width: 100%;
    height: 3.75rem;
    background-color: ${(props) => props.theme.navigationResponsive.dataArea.background};
`

export const NavigationContent = styled.div`
    width: 100%;
    height: 100%;
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: ${spacing(3)};
`

export const Loading = styled.div`
    background-color: ${(props) => props.theme.loadingPage.background};
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`

export const Screen = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
`
