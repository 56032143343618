import {TableDataCell, TableDataRow} from './_styled/table-row.styled'
import {CustomerReports} from '../../../../values/customer-reports'
import {ReportsAttachmentsTable} from './report-attachments-table/reports-attachments-table'
import {StarRating} from '../shared/star-rating/star-rating'
import {formatDateWithoutTime} from '../../../../helpers/formatting'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {locationMapSelector} from '../../../../store/state/locations/selectors'
import {basalGangliaEnvMapSelector} from '../../../../store/state/basal-ganglia-env/selectors'
import {getBasalGangliaEnvDescription, getLocationDescription} from '../data-helpers'

interface TableRowProps {
    report: CustomerReports
    index: number
}
export function TableRow({report, index}: TableRowProps): JSX.Element {
    const locationMap = useTypedSelector(locationMapSelector)
    const shipManagerMap = useTypedSelector(basalGangliaEnvMapSelector)

    const gridRow = index + 1
    return (
        <>
            <TableDataRow gridRow={gridRow} id={`data-row_${report.identity}`} />
            <TableDataCell
                gridRow={gridRow}
                gridColumn={2}
                id={`reports-vessel_${report.identity}`}
            >
                {getLocationDescription(locationMap, report.location)}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={3}
                id={`reports-ship-manager_${report.identity}`}
            >
                {getBasalGangliaEnvDescription(
                    shipManagerMap,
                    report.basalGangliaEnvironmentIdentity,
                )}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={4}
                id={`reports-report-type_${report.identity}`}
            >
                {report.reportType}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={5}
                id={`reports-rating_${report.identity}`}
            >
                <StarRating rating={report.rating} />
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={6}
                id={`reports-report-id_${report.identity}`}
            >
                {report.reportCode}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={7}
                id={`reports-start-date_${report.identity}`}
            >
                {formatDateWithoutTime(report.periodStartDate)}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={8}
                id={`reports-end-date_${report.identity}`}
            >
                {formatDateWithoutTime(report.periodEndDate)}
            </TableDataCell>
            <ReportsAttachmentsTable report={report} gridRow={gridRow} />
        </>
    )
}
