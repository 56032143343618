import TagManager from 'react-gtm-module'

export function addEvent<T>(eventName: string, data: T): boolean {
    try {
        TagManager.dataLayer({
            dataLayer: {
                event: 'CyberOwlCustomEvent',
                cyberOwlEventName: eventName,
                cyberOwlEventParams: data,
            },
        })
        return true
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        return false
    }
}
