import {isEqual} from 'lodash'
import {CustomerReports} from '../../../../values/customer-reports'
import LoadingState from '../../../../values/loading-state-enum'
import {PagedReportsState} from '../types/paged-reports-state'
import {ActionType} from './action-type'
import {AllActions} from './actions'
import SessionActionType from '../../../../store/state/session-data/action-type'
import {DEFAULT_PAGED_REPORTS_STATE} from '../types/default-paged-reports-state'
import {LoggingOutAction} from '../../../../store/state/session-data/actions'
import produce from 'immer'

export const pagedReportsReducer = produce(
    (draft: PagedReportsState, action: AllActions | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.SET_SELECTED_PAGE:
                draft.selectedPage = action.payload
                break

            case ActionType.REQUEST_INITIAL_PAGE_DATA_ACTION:
                draft.loading = LoadingState.RequestingData
                draft.dataReportsMap = undefined
                draft.totalNumberOfReports = undefined
                draft.totalNumberOfPages = undefined
                draft.selectedPage = 0
                break
            case ActionType.SWITCH_TO_CACHED_PAGE_DATA_ACTION:
                draft.selectedPage = action.payload
                draft.loading = LoadingState.Loaded
                break
            case ActionType.REQUEST_PAGE_DATA_ACTION:
                draft.selectedPage = action.payload
                draft.loading = LoadingState.RequestingData
                break

            case ActionType.RECEIVED_REQUESTED_PAGE_DATA_ACTION:
                draft.loading = LoadingState.Loaded
                draft.totalNumberOfReports = action.payload.total
                draft.totalNumberOfPages = Math.ceil(action.payload.total / draft.pageSize)
                draft.earliestPeriodEndDate = action.payload.earliestPeriodEndDate
                draft.latestPeriodEndDate = action.payload.latestPeriodEndDate
                if (!isEqual(draft.allReportTypes, action.payload.allReportTypes)) {
                    draft.allReportTypes = action.payload.allReportTypes
                }
                const currSelectedPage = draft.selectedPage || 0

                if (!draft.dataReportsMap) {
                    draft.dataReportsMap = new Map<number, CustomerReports[]>()
                }
                if (
                    !draft.dataReportsMap.has(currSelectedPage) ||
                    !isEqual(draft.dataReportsMap.get(currSelectedPage), action.payload.items)
                ) {
                    draft.dataReportsMap.set(currSelectedPage, action.payload.items)
                }
                break

            case ActionType.SET_FILTER:
                draft.loading = LoadingState.RequestingData
                draft.dataReportsMap = undefined
                draft.totalNumberOfReports = undefined
                draft.totalNumberOfPages = undefined
                draft.selectedPage = 0
                if (!isEqual(draft.locations, action.payload.locations)) {
                    draft.locations = action.payload.locations
                }
                draft.rating = action.payload.rating
                draft.timeRangeFrom = action.payload.timeRangeFrom
                draft.timeRangeTo = action.payload.timeRangeTo
                draft.selectedReportType = action.payload.selectedReportType
                draft.basalGangliaEnvironments = action.payload.basalGangliaEnvironments
                draft.includeWholeFleetReports = action.payload.includeWholeFleetReports
                break
            case ActionType.RESET_CUSTOMER_REPORTS:
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_PAGED_REPORTS_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)
