import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'

interface Props {
    rightAlign?: boolean
    width: number
}

export const Container = styled.div<Props>`
    display: flex;
    justify-content: ${(props) => (props.rightAlign ? 'flex-end' : 'space-between')};
    align-items: center;
    margin-bottom: ${spacing(2)};
    padding: 0;
    column-gap: ${spacing(2)};
`
