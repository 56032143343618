import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import * as Styles from './action-bar.styled'
import {RefreshButton} from './refresh-button'
import {showFilterBarOnScreen} from '../data-helpers'
import {ShowFilterButton} from './show-filter-button'
import {useEffect} from 'react'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {reportsFiltersSelector} from '../../../../store/state/report-filters/selectors'
import {usePagedReports} from '../../contexts/use-paged-reports'

export function ActionBar(): JSX.Element {
    const {width} = useDimensions()
    const showFilterBar = showFilterBarOnScreen(width)
    const {setFilter} = usePagedReports()

    const {
        locations,
        selectedRating,
        selectedFromTimeRange,
        selectedToTimeRange,
        selectedReportType,
        selectedBasalGangliaEnvs,
        includeWholeFleetReports,
    } = useTypedSelector(reportsFiltersSelector)

    useEffect(() => {
        setFilter(
            locations,
            selectedRating,
            selectedFromTimeRange,
            selectedToTimeRange,
            selectedReportType,
            selectedBasalGangliaEnvs,
            includeWholeFleetReports,
        )
        // setFilter method is defined inside the usePagedReports function, each time that is called, we get a new instance of that function. If we move it into the dependency of useEffect, we end up with this run away triggering of useEffect as the thing that needs to be updated, causes the triggering of the code that does the update.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedRating,
        locations,
        selectedFromTimeRange,
        selectedReportType,
        selectedToTimeRange,
        selectedBasalGangliaEnvs,
        includeWholeFleetReports,
    ])

    return (
        <Styles.Container rightAlign={showFilterBar} width={width}>
            {!showFilterBar && <ShowFilterButton />}
            <RefreshButton />
        </Styles.Container>
    )
}
