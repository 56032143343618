import {NoDataMessage, NoDataMessageGrid} from './no-data.styled'
import * as Styles from '../_styled/table-row.styled'

export function NoDataTable(): JSX.Element {
    return (
        <NoDataMessageGrid>
            <Styles.TableDataRow gridRow={1} />
            <Styles.TableDataCell gridRow={1} gridColumn={5} centered>
                <NoDataMessage>No reports found.</NoDataMessage>
            </Styles.TableDataCell>
        </NoDataMessageGrid>
    )
}
