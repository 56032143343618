import {CustomerReportAttachmentData} from '../../../../../values/customer-reports'
import {DownloadIcon} from './download-icon'
import * as Styles from './attachment-button.styled'
import {usePagedReports} from '../../../contexts/use-paged-reports'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {viewAttachmentEvent} from '../../../../../core/google-tag-manager/reports/view-attachment-event'

interface AttachmentButtonsProps {
    identity: GuidType
    attachments: CustomerReportAttachmentData[] | undefined
    rating: number
    reportType: string
    basalGangliaEnvironmentIdentity: GuidType
}
export function AttachmentButtons({
    identity,
    attachments,
    rating,
    reportType,
    basalGangliaEnvironmentIdentity,
}: AttachmentButtonsProps): JSX.Element | null {
    const {downloadCustomerReports, selectedPage} = usePagedReports()

    const onDownload = (
        reportIdentity: GuidType,
        attachment: CustomerReportAttachmentData,
        basalGangliaEnvironmentIdentity: GuidType,
    ): void => {
        downloadCustomerReports(reportIdentity, attachment, basalGangliaEnvironmentIdentity)
        viewAttachmentEvent(attachment.contentType, (selectedPage || 0) + 1, rating, reportType)
    }

    if (!attachments) {
        return null
    }

    return (
        <Styles.AttachmentButtonWrapper>
            {attachments &&
                attachments.map((attachment) => (
                    <Styles.AttachmentButton
                        key={attachment.identity}
                        id={`attachment-${attachment.identity}`}
                        disabled={attachment == null}
                        onClick={() => {
                            onDownload(identity, attachment, basalGangliaEnvironmentIdentity)
                        }}
                    >
                        <DownloadIcon contentType={attachment.contentType} />
                    </Styles.AttachmentButton>
                ))}
        </Styles.AttachmentButtonWrapper>
    )
}
