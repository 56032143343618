import {addEvent} from '../add-event'
import {getReportType} from '../helpers/report-type-helper'

export function selectReportTypeEvent(
    currentReportType: string | undefined,
    newReportType: string,
): boolean {
    return addEvent('reportsPageSelectReportType', {
        currentReportType: getReportType(currentReportType),
        newReportType: getReportType(newReportType),
    })
}
