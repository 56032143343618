import {GuidType} from '../../../values/generic-type-defintions'

export type Role = 'INTERNAL' | 'EXTERNAL'
export interface UserRole {
    identity: GuidType
    role: Role
    userIdentity: GuidType
}
export interface RolesReduxState {
    isFetching: boolean
    userRoles: UserRole[]
}

export const DEFAULT_ROLES_STATE: RolesReduxState = {
    isFetching: true,
    userRoles: new Array<UserRole>(),
}
