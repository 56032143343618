import {ReportsFiltersReduxState} from './state'
import AppState from '../../types/app-state'
import LoadingState from '../../../values/loading-state-enum'
import {LocationIdType} from '../locations/state'
import {GuidType} from '../../../values/generic-type-defintions'

export const reportsFiltersSelector = (state: AppState): ReportsFiltersReduxState =>
    state.reportsFilter

export const reportsFilterToDataSelector = (state: AppState): string | undefined =>
    state.reportsFilter.selectedToTimeRange

export const reportsFilterReportTypeSelector = (state: AppState): string | undefined =>
    state.reportsFilter.selectedReportType

export const reportsFilterFromDataSelector = (state: AppState): string | undefined =>
    state.reportsFilter.selectedFromTimeRange

export const reportsFilterStarRatingSelector = (state: AppState): number =>
    state.reportsFilter.selectedRating

export const reportsFilterVesselsSelector = (state: AppState): Set<LocationIdType> | undefined =>
    state.reportsFilter.locations
export const reportsFilterBasalGangliaEnvsSelector = (state: AppState): GuidType | undefined =>
    state.reportsFilter.selectedBasalGangliaEnvs
export const reportsFilterIsLoadingSelector = (state: AppState): boolean =>
    state.reportsFilter.loadingState === LoadingState.NotPopulated ||
    state.reportsFilter.loadingState === LoadingState.RequestingData

export const reportsFilterLoadingStateSelector = (state: AppState): LoadingState =>
    state.reportsFilter.loadingState
export const reportsFilterWholeReportsSelector = (state: AppState): boolean =>
    state.reportsFilter.includeWholeFleetReports
