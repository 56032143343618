import {Checkbox} from '../../../../../components/form-elements/checkbox/checkbox'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {locationMapSelector} from '../../../../../store/state/locations/selectors'
import {LocationIdType} from '../../../../../store/state/locations/state'

interface Props {
    locationId: LocationIdType
    checked: boolean
    setChecked: (locationId: LocationIdType, checked: boolean) => void
}
export function VesselRow({locationId, checked, setChecked}: Props): JSX.Element {
    const location = useTypedSelector(locationMapSelector).get(locationId)

    return (
        <Checkbox
            id={`filter-${locationId}`}
            checked={checked}
            onChange={() => setChecked(locationId, !checked)}
            label={location?.description || 'Unknown'}
        />
    )
}
