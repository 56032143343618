import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {Aside} from '../../aside/aside'
import {Header, HeaderSize} from '../../header/header'
import * as Styled from './column-based-layout.styled'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {errorSelector} from '../../../../../store/state/session-data/selectors'
import {ReactNode, ReactNodeArray} from 'react'
import {LoginPageTemplate} from '../../../page-template/login-page-template'
import {AdminProps} from '../../../login-page'

function getMarginRight(width: number): Styled.DetailRightMargin {
    return width > 1290
        ? Styled.DetailRightMargin.WIDE
        : width > 920
        ? Styled.DetailRightMargin.NORMAL
        : Styled.DetailRightMargin.NARROW
}

export function needsReducedHeight(height: number): boolean {
    return height < 330
}

export function needsReducedWidth(width: number): boolean {
    return width < 769
}

interface ColumnBasedLoginLayoutProps extends AdminProps {
    children: ReactNode | ReactNodeArray
}

export function ColumnBasedLoginLayout({
    children,
    admin = false,
}: ColumnBasedLoginLayoutProps): JSX.Element {
    const {width, height} = useDimensions()
    const error = useTypedSelector(errorSelector)
    const hasError = error ? error.length > 0 : false

    const reducedHeight = needsReducedHeight(height)
    const reducedWidth = needsReducedWidth(width)
    return (
        <LoginPageTemplate>
            <Styled.Content>
                <Styled.Detail rightMargin={getMarginRight(width)}>
                    <Header headerSize={HeaderSize.LARGE} />
                    <Styled.AsideWrapper width={width}>
                        <Aside />
                    </Styled.AsideWrapper>
                </Styled.Detail>
                <Styled.CallToActionSection
                    reducedHeight={reducedHeight}
                    reducedWidth={reducedWidth}
                    showError={hasError}
                    admin={admin}
                >
                    {children}
                </Styled.CallToActionSection>
            </Styled.Content>
        </LoginPageTemplate>
    )
}
