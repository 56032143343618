/**
 * Ensures value(s) strings and has a size after trim
 * @param strings
 * @returns {boolean}
 */
export function validString(...strings: unknown[]): boolean {
    if (strings.length === 0) {
        return false
    }
    for (const currString of strings) {
        if (typeof currString !== 'string') {
            return false
        }
        if (currString.length === 0 || currString.trim().length === 0) {
            return false
        }
    }
    return true
}

export function isBrowserDarkMode(): boolean {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
}
