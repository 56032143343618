import {GuidType} from '../../../values/generic-type-defintions'
import LoadingState from '../../../values/loading-state-enum'

export interface UsersReduxState {
    loadingState: LoadingState
    users: UserData[]
}
export interface UserData {
    Identity: GuidType
    Username: string
    Email: string
}
export const DEFAULT_USERS_STATE: UsersReduxState = {
    loadingState: LoadingState.NotPopulated,
    users: new Array<UserData>(),
}
