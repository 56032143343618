import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {ComplianceRating} from './compliance-rating/compliance-rating'
import * as Styles from './filter-bar.styled'
import {FilterBarHeader} from './header/filter-bar-header'
import {ReportType} from './report-type/report-type'
import {ResetFilterButton} from './reset-filter/reset-filter-button'
import {ShipManager} from './ship-manager/ship-manager'
import {TimePeriodFrom} from './time-period/time-period-from'
import {TimePeriodTo} from './time-period/time-period-to'
import {VesselsFilter} from './vessel-filter/vessels-filter'
import {WholeFleetReports} from './whole-fleet-reports/whole-fleet-reports'

export function FilterBarOnScreen(): JSX.Element {
    const {width} = useDimensions()

    return (
        <Styles.FilterBarStyled>
            <FilterBarHeader />
            <Styles.SectionContent width={width}>
                <ReportType />
                <ShipManager />
                <ComplianceRating />
                <TimePeriodFrom />
                <TimePeriodTo />
                <WholeFleetReports />
            </Styles.SectionContent>
            <Styles.SectionVessels>
                <VesselsFilter />
            </Styles.SectionVessels>
            <ResetFilterButton />
        </Styles.FilterBarStyled>
    )
}
