import {useTheme} from 'styled-components'
import {CyberOwlTheme} from '../../../../../theme/theme'

interface StarIconProps {
    rating: number
    percentageThreshold: number
}
function getFillColor(rating: number, percentageNumber: number, theme: CyberOwlTheme): string {
    return rating >= percentageNumber
        ? theme.reports.starRating.staticIcon.selected
        : theme.reports.starRating.staticIcon.unSelected
}
export function StarIcon({rating, percentageThreshold}: StarIconProps): JSX.Element {
    const theme = useTheme()
    return (
        <svg width={19} height={19} preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 15">
            <path
                // tslint:disable-next-line: max-line-length
                d="M8,0l2,5.578,6,.151-4.764,3.6L12.944,15,8,11.646,3.056,15,4.764,9.328,0,5.729l6-.151Z"
                fill={getFillColor(rating, percentageThreshold, theme)}
            />
        </svg>
    )
}
