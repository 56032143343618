import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {formatNumber} from '../../../../../helpers/formatting'
import {usePagedReports} from '../../../contexts/use-paged-reports'
import * as Styles from './record-set.styled'

export function RecordSet(): JSX.Element | null {
    const {width} = useDimensions()
    const {totalNumberOfReports, selectedPage, pageSize} = usePagedReports()
    if (totalNumberOfReports == undefined || selectedPage == undefined || pageSize == undefined) {
        return null
    }

    const startingIndicator = selectedPage * pageSize + 1
    const endIndicator =
        startingIndicator + pageSize > totalNumberOfReports
            ? totalNumberOfReports
            : startingIndicator + pageSize - 1

    return (
        <>
            {totalNumberOfReports !== 0 && (
                <Styles.RecordSet width={width}>
                    Showing
                    <Styles.Value id="recordset-starting-value">
                        {formatNumber(startingIndicator)}
                    </Styles.Value>
                    to
                    <Styles.Value id="recordset-end-value">
                        {formatNumber(endIndicator)}
                    </Styles.Value>
                    of
                    <Styles.Value id="recordset-total-value">
                        {formatNumber(totalNumberOfReports)}
                    </Styles.Value>
                </Styles.RecordSet>
            )}
        </>
    )
}
