import LoadingState from '../../../../values/loading-state-enum'
import {usePagedReports} from '../../contexts/use-paged-reports'
import {LoadingData} from '../../loading-data'
import {ReportsTableLoaded} from './reports-table-loaded'

export function ReportsTableContent(): JSX.Element {
    const {loading, totalNumberOfReports} = usePagedReports()

    const showLoading =
        totalNumberOfReports == undefined ||
        loading === LoadingState.NotPopulated ||
        loading === LoadingState.RequestingData

    if (showLoading) {
        return <LoadingData />
    }
    return <ReportsTableLoaded />
}
