import * as Styles from './_styles/nav-bar-links.styled'
import {NavBarLink} from './nav-bar-link'
import {viewReportsEvent} from '../../../../core/google-tag-manager/navigation/view-reports-event'

export function NavBarLinks(): JSX.Element {
    return (
        <Styles.NavBarLinks>
            <NavBarLink
                id="navigation-reports"
                path="/reports"
                navLinkText="Reports"
                gtmLogger={() => viewReportsEvent('mainNavigation', 'v1')}
            />
        </Styles.NavBarLinks>
    )
}
