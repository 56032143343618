import styled from 'styled-components'

export const DisabledButton = styled.span`
    display: flex;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    background-color: ${(props) => props.theme.pagingButton.disabled.background};
    color: ${(props) => props.theme.pagingButton.disabled.text};
    border: 1px solid ${(props) => props.theme.pagingButton.disabled.border};
    border-radius: 4px;
    height: 27px;
    width: 27px;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
`
