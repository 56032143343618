import {Filter} from 'react-feather'
import {useToggle} from '../../../../contexts/toggle/use-toggle'
import {showFilterEvent} from '../../../../core/google-tag-manager/reports/show-filter-event'

import * as Styles from './button.styled'
export function ShowFilterButton(): JSX.Element {
    const {setValue: showFilter} = useToggle()

    return (
        <Styles.Button
            onClick={(e) => {
                e.preventDefault()
                showFilterEvent()
                showFilter(true)
            }}
        >
            <Filter height={16} width={16} />
            <Styles.Text>Filter by</Styles.Text>
        </Styles.Button>
    )
}
