import {ButtonData} from '../../components/paging/basic/button-data'
import {addEvent} from './add-event'

export function pagingButtonClickedEvent(gtmEventPrefix: string, button: ButtonData): boolean {
    return addEvent(`${gtmEventPrefix}Paging`, {
        pageIndex: button.pageIndex,
        buttonType: button.type,
        buttonText: button.text,
    })
}
