import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {smallFont} from '../../../../../theme/font-styles'

export const Vessels = styled.div`
    display: grid;
    grid-template-columns: fit-content(auto);
    column-gap: ${spacing(2)};
    row-gap: ${spacing(2)};
    align-items: center;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

const Base = styled.div`
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.32px;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`

export const VesselsHeadingRow = styled(Base)`
    grid-column: 1 / span 1;
`
export const SectionContent = styled.div`
    padding: ${spacing(3)} ${spacing(4)};
    margin: 0;
    width: 100%;
`
export const VesselNarrow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${spacing(4)};
`
export const Label = styled.label`
    font-size: 14px;
    margin-bottom: 0;
`
export const Input = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;
`
export const Select = styled.select`
    text-align-last: center;
    padding: 3px ${spacing(4)} 3px ${spacing(6)};
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    font-size: 12px;
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    border-radius: 3px;
`
