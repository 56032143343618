import {GuidType} from '../../../values/generic-type-defintions'

export type LocationIdType = GuidType

export interface Location {
    location: LocationIdType
    code: string
    description: string
    type?: string
    basalGangliaEnvironmentIdentity?: GuidType
}

export type LocationMap = Map<LocationIdType, Location>
export interface LocationsReduxState {
    isFetching: boolean
    locations: Location[]
    locationMap: LocationMap
}

export const DEFAULT_LOCATIONS_STATE: LocationsReduxState = {
    isFetching: true,
    locations: [],
    locationMap: new Map<LocationIdType, Location>(),
}

const UNKNOWN_VALUE = 'Unknown'
export const getLocationDescription = (
    locations: LocationMap,
    location: LocationIdType,
): string | typeof UNKNOWN_VALUE => {
    try {
        return locations?.get(location)?.description || UNKNOWN_VALUE
    } catch {
        // eslint-disable-next-line no-console
        console.error(`location ${location} does not match a location in the database`)
        return UNKNOWN_VALUE
    }
}
