import AppState from './app-state'
import {DEFAULT_AUTH_STATE} from '../state/auth/state'
import {DEFAULT_CONFIG_STATE} from '../state/config/state'
import {DEFAULT_ROLES_STATE} from '../state/roles/state'
import {DEFAULT_USERS_STATE} from '../state/users/state'
import {DEFAULT_SESSION_DATA_STATE} from '../state/session-data/state'
import {DEFAULT_CURRENT_USER_STATE} from '../state/current-user/state'
import {DEFAULT_LOCATIONS_STATE} from '../state/locations/state'
import {DEFAULT_REPORTS_FILTERS_STATE} from '../state/report-filters/state'
import {DEFAULT_BASAL_GANGLIA_ENV_STATE} from '../state/basal-ganglia-env/state'

export const INITIAL_STORE: AppState = {
    auth: DEFAULT_AUTH_STATE,
    config: DEFAULT_CONFIG_STATE,
    roles: DEFAULT_ROLES_STATE,
    users: DEFAULT_USERS_STATE,
    sessionData: DEFAULT_SESSION_DATA_STATE,
    currentUser: DEFAULT_CURRENT_USER_STATE,
    locations: DEFAULT_LOCATIONS_STATE,
    reportsFilter: DEFAULT_REPORTS_FILTERS_STATE,
    basalGangliaEnv: DEFAULT_BASAL_GANGLIA_ENV_STATE,
}
