import {MouseEvent} from 'react'
import {Button} from './styling/button.styled'
import {DisabledButton} from './styling/disabled-button.styled'
import {LargerButton} from './styling/larger-button.styled'
import {LargerDisabledButton} from './styling/larger-disabled-button.styled'
import {LargestButton} from './styling/largest-button.styled'
import {LargestDisabledButton} from './styling/largest-disabled-button.styled'
import {PlaceHolder} from './styling/place-holder.styled'
import {SelectedPage} from './styling/selected-page.styled'
import {ButtonData, ButtonType} from './button-data'

function getKey(button: ButtonData): string {
    switch (button.type) {
        case ButtonType.LargestNumeric:
        case ButtonType.LargerNumeric:
        case ButtonType.Numeric:
            return `numeric_${button.text}`
        case ButtonType.EndNumeric:
            return `endnumeric_${button.text}`
        case ButtonType.DownPage:
        case ButtonType.UpPage:
        case ButtonType.DownDots:
        case ButtonType.UpDots:
        default:
            return button.type
    }
}

export class StyledButtonFactory {
    public static build(
        button: ButtonData,
        pageHandler: (event: MouseEvent, button: ButtonData) => void,
        prefix = 'paging',
    ): JSX.Element {
        const key = `${prefix}_${getKey(button)}`
        if (button.disabled) {
            switch (button.type) {
                case ButtonType.LargerNumeric:
                    return (
                        <LargerDisabledButton id={key} key={key}>
                            {button.text}
                        </LargerDisabledButton>
                    )
                case ButtonType.LargestNumeric:
                    return (
                        <LargestDisabledButton id={key} key={key}>
                            {button.text}
                        </LargestDisabledButton>
                    )
                default:
                    return (
                        <DisabledButton id={key} key={key}>
                            {button.text}
                        </DisabledButton>
                    )
            }
        }

        switch (button.type) {
            case ButtonType.DownPage:
                return (
                    <Button id={key} key={key} onClick={(e) => pageHandler(e, button)}>
                        &lt;
                    </Button>
                )
            case ButtonType.LargestNumeric:
                return (
                    <LargestButton id={key} key={key} onClick={(e) => pageHandler(e, button)}>
                        {button.text}
                    </LargestButton>
                )
            case ButtonType.LargerNumeric:
                return (
                    <LargerButton id={key} key={key} onClick={(e) => pageHandler(e, button)}>
                        {button.text}
                    </LargerButton>
                )
            case ButtonType.EndNumeric:
            case ButtonType.Numeric:
                return button.selected ? (
                    <SelectedPage id={key} key={key}>
                        {button.text}
                    </SelectedPage>
                ) : (
                    <Button id={key} key={key} onClick={(e) => pageHandler(e, button)}>
                        {button.text}
                    </Button>
                )
            case ButtonType.UpPage:
                return (
                    <Button id={key} key={key} onClick={(e) => pageHandler(e, button)}>
                        &gt;
                    </Button>
                )
            case ButtonType.DownDots:
            case ButtonType.UpDots:
                return (
                    <Button id={key} key={key} onClick={(e) => pageHandler(e, button)}>
                        ...
                    </Button>
                )
            /* istanbul ignore next */
            default:
                return <PlaceHolder id={key} key={key} />
        }
    }
}
