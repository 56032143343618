import styled from 'styled-components'
import {spacing} from '../../../theme/spacing'

export const Label = styled.label`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
`
interface TextProps {
    missingLabel?: boolean
}
export const Text = styled.span<TextProps>`
    margin-left: ${(props) => (props.missingLabel ? '' : spacing(2))};
    vertical-align: middle;
`
