export enum ActionType {
    SET_SELECTED_PAGE = '[PAGED REPORTS CONTEXT] SET SELECTED PAGE',
    CHANGE_PAGE_SIZE_ACTION = '[PAGED ALERT CONTEXT] CHANGE PAGE SIZE ACTION',
    REQUEST_INITIAL_PAGE_DATA_ACTION = '[PAGED REPORTS CONTEXT] REQUEST INITIAL PAGE DATA ACTION',
    SWITCH_TO_CACHED_PAGE_DATA_ACTION = '[PAGED REPORTS CONTEXT] SWITCH TO CACHED PAGE DATA ACTION',
    REQUEST_PAGE_DATA_ACTION = '[PAGED REPORTS CONTEXT] REQUEST PAGE DATA ACTION',
    RECEIVED_REQUESTED_PAGE_DATA_ACTION = '[PAGED REPORTS CONTEXT] RECEIVED REQUESTED PAGE DATA ACTION',
    RESET_CUSTOMER_REPORTS = '[PAGED REPORTS CONTEXT] RESET CUSTOMER_REPORTS',
    SET_FILTER = '[PAGED REPORTS CONTEXT] SET FILTER',
}
