import {TableHeaderContent} from './table-header-content.styled'

interface ReportsTableColumnProps {
    gridColumn: number
    text: string
    colName: string
}
export function ReportsTableColumn({
    gridColumn,
    text,
    colName,
}: ReportsTableColumnProps): JSX.Element {
    return (
        <TableHeaderContent id={`reports-table-${colName}`} gridColumn={gridColumn}>
            {text}
        </TableHeaderContent>
    )
}
