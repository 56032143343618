export enum ButtonType {
    DownPage = 'DOWN_PAGE',
    DownDots = 'DOWN_DOTS',
    EndNumeric = 'END_NUMERIC',
    Numeric = 'NUMERIC',
    UpDots = 'UP_DOTS',
    UpPage = 'UP_PAGE',
    LargerNumeric = 'LARGER_NUMERIC',
    LargestNumeric = 'LARGEST_NUMERIC',
}

export interface ButtonData {
    type: ButtonType
    pageIndex?: number
    text?: string
    selected?: boolean
    disabled?: boolean
}
