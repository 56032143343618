import styled from 'styled-components'

export const Button = styled.button`
    border: 1px solid #ccc;
    height: 19px;
    width: 19px;
    background: none;
    margin: 0;
    padding: 0;
    appearance: none;
    border-radius: 3px;
    &:hover {
        appearance: none;
        border: 1px solid #1f88e5;
    }
`
