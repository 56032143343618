import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {CustomerReports} from '../../../../values/customer-reports'
import {CardDataCell} from './card-data-cell'
import * as Styles from './_styled/report-card.styled'
import {ReportsAttachmentsCard} from './reports-attachments-card/reports-attachments-card'
import {formatDateWithoutTime} from '../../../../helpers/formatting'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {locationMapSelector} from '../../../../store/state/locations/selectors'
import {basalGangliaEnvMapSelector} from '../../../../store/state/basal-ganglia-env/selectors'
import {getBasalGangliaEnvDescription, getLocationDescription} from '../data-helpers'
interface ReportCardProps {
    report: CustomerReports
}
export function ReportCard({report}: ReportCardProps): JSX.Element {
    const {width} = useDimensions()
    const locationMap = useTypedSelector(locationMapSelector)
    const shipManagerMap = useTypedSelector(basalGangliaEnvMapSelector)

    return (
        <Styles.Card id={`reports-card_${report.identity}`}>
            <Styles.CardBorder>
                <Styles.CardAreaTitle width={width}>
                    <Styles.CardReportCodeTitleData
                        id={`reports-reportId_${report.identity}`}
                        gridArea="REPORTID"
                    >
                        {report.reportCode}
                    </Styles.CardReportCodeTitleData>
                    <Styles.CardReportTypeTitleData
                        id={`reports-reportType_${report.identity}`}
                        gridArea="REPORTTYPE"
                    >
                        {report.reportType}
                    </Styles.CardReportTypeTitleData>
                </Styles.CardAreaTitle>
                <Styles.CardAreaContent width={width}>
                    <CardDataCell
                        gridArea="SHIP-MANAGER"
                        label="Ship Manager:"
                        report={report}
                        identifier="ship-manager"
                    >
                        {getBasalGangliaEnvDescription(
                            shipManagerMap,
                            report.basalGangliaEnvironmentIdentity,
                        )}
                    </CardDataCell>
                    <CardDataCell
                        gridArea="VESSEL"
                        label="Vessel:"
                        report={report}
                        identifier="vessel"
                    >
                        {getLocationDescription(locationMap, report.location)}
                    </CardDataCell>
                    <CardDataCell
                        gridArea="START-DATE"
                        label="Period From:"
                        report={report}
                        identifier="periodFrom"
                    >
                        {formatDateWithoutTime(report.periodStartDate)}
                    </CardDataCell>
                    <CardDataCell
                        gridArea="END-DATE"
                        label="Period To:"
                        report={report}
                        identifier="periodTo"
                    >
                        {formatDateWithoutTime(report.periodEndDate)}
                    </CardDataCell>
                    <ReportsAttachmentsCard report={report} />
                </Styles.CardAreaContent>
            </Styles.CardBorder>
        </Styles.Card>
    )
}
