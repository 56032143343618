import ActionType from './action-type'
import {Action} from './actions'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import {DEFAULT_REPORTS_FILTERS_STATE, ReportsFiltersReduxState} from './state'
import LoadingState from '../../../values/loading-state-enum'
import {LocationIdType} from '../locations/state'
import produce from 'immer'

export const reportsFiltersReducer = produce(
    (
        draft: ReportsFiltersReduxState = DEFAULT_REPORTS_FILTERS_STATE,
        action: Action | LoggingOutAction,
    ): ReportsFiltersReduxState => {
        switch (action.type) {
            case ActionType.TOGGLE_ALL_VESSELS:
                draft.locations = action.payload ? undefined : new Set<LocationIdType>()
                break

            case ActionType.TOGGLE_VESSEL:
                const {allLocations, location, newValue} = action.payload

                if (draft.locations == undefined) {
                    draft.locations = new Set<LocationIdType>(allLocations)
                }

                if (newValue) {
                    draft.locations.add(location)
                } else {
                    draft.locations.delete(location)
                }

                if (draft.locations.size === allLocations?.length) {
                    draft.locations = undefined
                }
                break
            case ActionType.SET_LOCATION:
                draft.locations = action.payload
                break
            case ActionType.SET_SELECTED_RATING:
                draft.selectedRating = action.payload
                break

            case ActionType.SET_SELECTED_TIME_RANGE_FROM:
                draft.selectedFromTimeRange = action.payload
                break

            case ActionType.SET_SELECTED_TIME_RANGE_TO:
                draft.selectedToTimeRange = action.payload
                break

            case ActionType.SET_SELECTED_REPORT_TYPE:
                draft.selectedReportType = action.payload
                break
            case ActionType.SET_SELECTED_BASAL_GANGLIA_ENV:
                draft.selectedBasalGangliaEnvs = action.payload
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_REPORTS_FILTERS_STATE
                break
            case ActionType.TOGGLE_WHOLE_FLEET_REPORTS:
                draft.includeWholeFleetReports = action.payload
                break
            case ActionType.RESET_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.locations = DEFAULT_REPORTS_FILTERS_STATE.locations
                draft.selectedRating = DEFAULT_REPORTS_FILTERS_STATE.selectedRating
                draft.selectedFromTimeRange = DEFAULT_REPORTS_FILTERS_STATE.selectedFromTimeRange
                draft.selectedToTimeRange = DEFAULT_REPORTS_FILTERS_STATE.selectedToTimeRange
                draft.allReportTypes = DEFAULT_REPORTS_FILTERS_STATE.allReportTypes
                draft.selectedReportType = DEFAULT_REPORTS_FILTERS_STATE.selectedReportType
                draft.selectedBasalGangliaEnvs =
                    DEFAULT_REPORTS_FILTERS_STATE.selectedBasalGangliaEnvs
                draft.includeWholeFleetReports =
                    DEFAULT_REPORTS_FILTERS_STATE.includeWholeFleetReports
                break
        }
        return draft
    },
)
