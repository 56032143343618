import moment, {Moment} from 'moment'
import {spacing} from '../../../theme/spacing'
import {LocationIdType, LocationMap} from '../../../store/state/locations/state'
import {FilterTimeRangeSelectOptionProps} from '../contexts/types/filter-time-range'
import {BasalGangliaDataMap} from '../../../store/state/basal-ganglia-env/state'
import {GuidType} from '../../../values/generic-type-defintions'

export function showCards(width: number): boolean {
    return width < 1500
}
export function showFilterBarOnScreen(width: number): boolean {
    return width > 720
}

export function narrowFilterLayout(width: number): boolean {
    return width > 400
}
export function getGridTemplateColumns(width: number): string {
    if (width > 1620) {
        return `${spacing(1)} 250px 110px 130px 100px 120px 120px 120px auto ${spacing(1)}`
    }
    if (width > 1600) {
        return `${spacing(1)} 230px 110px 130px 100px 120px 120px 120px auto ${spacing(1)}`
    }
    if (width > 1560) {
        return `${spacing(1)} 200px 105px 120px 100px 110px 120px 120px auto ${spacing(1)}`
    }
    if (width > 1520) {
        return `${spacing(1)} 190px 105px 120px 95px 105px 110px 110px auto ${spacing(1)}`
    }

    return `${spacing(1)} 190px 100px 120px 95px 100px 110px 110px auto ${spacing(1)}`
}

export function getRatingBoundarybuttons(): number[] {
    return [10, 33, 66, 90, 101]
}
export function showHamburgerMenu(width: number): boolean {
    return width < 1250
}
export function getHorizontalPagePadding(width: number): string {
    if (width > 1660) {
        return '0'
    }
    if (width > 1655) {
        return spacing(1)
    }
    if (width > 1650) {
        return spacing(2)
    }
    if (width > 1645) {
        return spacing(3)
    }
    if (width < 1025) {
        return spacing(3)
    }
    return spacing(4)
}
export const BREAK_POINT_CARD_REDUCED_TEXT = 1160

export function getReportMonths(
    startDate: Moment,
    endDate: Moment,
    endOfMonth?: boolean,
): FilterTimeRangeSelectOptionProps[] {
    const reportMonths = []
    const fromDate = endOfMonth
        ? moment(startDate).clone().utc().endOf('month').endOf('day')
        : moment(startDate).clone().utc().startOf('month').startOf('day')
    const toDate = endOfMonth
        ? moment(endDate).clone().utc().endOf('month').endOf('day')
        : moment(endDate).clone().utc().startOf('month').startOf('day')

    while (toDate > fromDate || fromDate.format('YYYY MMMM') === toDate.format('YYYY MMMM')) {
        reportMonths.push({
            label: fromDate.format('YYYY MMMM'),
            value: fromDate.utc().format(),
        })
        fromDate.add(1, 'month')
    }

    return reportMonths
}
export function getFormattedSelectedVessels(
    selectedVessels: Set<LocationIdType> | undefined,
): LocationIdType[] {
    if (!selectedVessels) {
        return []
    }
    return Array.from(selectedVessels)
}

const UNKNOWN_VALUE = 'Unknown'
export const getBasalGangliaEnvDescription = (
    basalGangliaEnv: BasalGangliaDataMap,
    identity: GuidType,
): string | typeof UNKNOWN_VALUE => {
    try {
        return basalGangliaEnv?.get(identity)?.Name || UNKNOWN_VALUE
    } catch {
        // eslint-disable-next-line no-console
        console.error(
            `basalGangliaEnv ${identity} does not match a basalGangliaEnv in the database`,
        )
        return UNKNOWN_VALUE
    }
}

const FLEET_VALUE = 'Whole fleet'
export const getLocationDescription = (
    locations: LocationMap,
    location: LocationIdType,
): string | typeof FLEET_VALUE => {
    if (!location) {
        return FLEET_VALUE
    }
    return locations?.get(location)?.description || FLEET_VALUE
}
