import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {showFilterBarOnScreen} from '../../data-helpers'

interface CardAreaProps {
    width: number
}

export const CardArea = styled.div<CardAreaProps>`
    flex: 1;
    padding: ${(props) => (!showFilterBarOnScreen(props.width) ? 0 : `${spacing(2)}`)};
    background-color: ${(props) => props.theme.reports.dataArea.background};
    overflow-x: hidden;
    overflow-y: auto;
`
export const ReportsCards = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: ${spacing(6)};
    min-width: 300px;
`
