import {UserData} from '../users/state'
export interface CurrentUserReduxState {
    isFetching: boolean
    currentUser: UserData
}

export const DEFAULT_CURRENT_USER_STATE: CurrentUserReduxState = {
    isFetching: true,
    currentUser: {
        Identity: '',
        Username: '',
        Email: '',
    },
}
