import {MouseEvent} from 'react'
import {pagingButtonClickedEvent} from '../../../core/google-tag-manager/paging-button-clicked-event'
import {buildButtons} from './button-array-builder'
import {ButtonData} from './button-data'
import {ShowPagingButtons} from './show-buttons'
import {StyledButtonFactory} from './styled-button-factory'
import {Paging} from './styling/paging.styled'
import {SpacingBlank} from './styling/spacing-blank.styled'

interface BasicPagingProps {
    gtmEventPrefix: string
    selectedPage: number | undefined
    totalNumberOfPages: number
    pagesToShow: number
    pageSize: number | undefined
    showButtons?: ShowPagingButtons
    selectPage: (pageIndex: number | undefined) => void
}

export function BasicPaging({
    gtmEventPrefix,
    selectedPage,
    totalNumberOfPages,
    selectPage,
    pagesToShow,
    pageSize,
    showButtons = {jump100Pages: false, jump10Pages: false, arrows: true, endNumbers: true},
}: BasicPagingProps): JSX.Element | null {
    if (totalNumberOfPages < 2) {
        return <SpacingBlank />
    }

    const buttons = buildButtons(
        selectedPage,
        totalNumberOfPages,
        pagesToShow,
        pageSize,
        showButtons,
    )

    function pageHandler(event: MouseEvent, button: ButtonData): void {
        pagingButtonClickedEvent(gtmEventPrefix, button)
        selectPage(button.pageIndex)
        event.preventDefault()
    }

    return (
        <Paging>{buttons.map((button) => StyledButtonFactory.build(button, pageHandler))}</Paging>
    )
}
