import TagManager from 'react-gtm-module'

export function setupTracking(
    trackingId: string | undefined,
    auth: string | undefined,
    preview: string | undefined,
    userId: string,
): boolean {
    try {
        if (!trackingId) {
            return false
        }

        const tagManagerArgs = {
            gtmId: trackingId,
            auth: auth || '',
            preview: preview || '',
            events: {
                customEvent: 'CyberOwlCustomEvent',
            },
            dataLayer: {
                userId,
            },
        }

        TagManager.initialize(tagManagerArgs)
        return true
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        return false
    }
}
