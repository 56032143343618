import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {AdminProps} from '../../../login-page'

export const Content = styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${(props) => props.theme.login.success.loginContent};
    align-items: flex-start;
`

interface AsideWrapperProps {
    height: number
}

export const AsideWrapper = styled.div<AsideWrapperProps>`
    margin-top: ${(props) => spacing(props.height >= 400 ? 8 : 4)};
    max-width: 398px;
`

interface MainActionsGridProps {
    useAvailableSpace?: boolean
}
export const MainActionsGrid = styled.div<MainActionsGridProps>`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: ${spacing(3)};
    ${(props) => (props.useAvailableSpace ? 'flex: 1; align-content: center;' : '')}
`

interface CallToActionSectionProps extends AdminProps {
    showError: boolean
}

export const CallToActionSection = styled.section<CallToActionSectionProps>`
    border-radius: ${spacing(3)};
    max-width: 400px;
    width: 100%;
    height: ${(props) => {
        if (props.admin) {
            return '80px'
        }
        return '200px'
    }};
    padding: ${spacing(4)};
    border: ${(props) =>
        props.showError ? `2px solid ${props.theme.login.error.errorBorder}` : 'none'};
    background-color: ${(props) =>
        props.showError
            ? props.theme.login.error.errorBackground
            : props.theme.login.success.loginBackground};
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
`
