import PersistableAppState from './types/persistable-app-state'

const localStorageKey = 'cyberowl-customer-portal'

export function loadState(): PersistableAppState | undefined {
    try {
        const serializedState: string | null = localStorage.getItem(localStorageKey)
        if (serializedState === null) {
            return undefined
        }

        return JSON.parse(serializedState)
    } catch (_) {
        return undefined
    }
}

export function saveState(state: PersistableAppState): void {
    try {
        const serializedState: string = JSON.stringify(state)

        localStorage.setItem(localStorageKey, serializedState)
    } catch (_) {
        // noop
    }
}
