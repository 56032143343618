import {Aside} from '../../aside/aside'
import {Header, HeaderSize} from '../../header/header'
import * as Styled from './row-based-layout.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {errorSelector} from '../../../../../store/state/session-data/selectors'
import {ReactNode, ReactNodeArray} from 'react'
import {LoginPageTemplate} from '../../../page-template/login-page-template'
import {AdminProps} from '../../../login-page'

export function isRowBasedLoginFormat(width: number): boolean {
    return width < 710
}

interface RowBasedLoginLayoutProps extends AdminProps {
    children: ReactNode | ReactNodeArray
}

export function RowBasedLoginLayout(props: RowBasedLoginLayoutProps): JSX.Element {
    const {height} = useDimensions()
    const error = useTypedSelector(errorSelector)
    const hasError = error ? error.length > 0 : false

    return (
        <LoginPageTemplate>
            <Styled.Content>
                <Header headerSize={HeaderSize.SMALL} />
                <Styled.CallToActionSection showError={hasError} admin={props.admin}>
                    {props.children}
                </Styled.CallToActionSection>
                <Styled.AsideWrapper height={height}>
                    <Aside layout="ROW" />
                </Styled.AsideWrapper>
            </Styled.Content>
        </LoginPageTemplate>
    )
}
