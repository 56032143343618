import {useDispatch} from 'react-redux'
import {resetFilterEvent} from '../../../../../core/google-tag-manager/reports/reset-filter-event'
import {resetFilter} from '../../../../../store/state/report-filters/action-creators'
import * as Styles from './reset-filter-button.styled'

export function ResetFilterNarrowButton(): JSX.Element {
    const dispatch = useDispatch()

    return (
        <Styles.NarrowButton
            id="reset-button-reports"
            onClick={(e) => {
                e.preventDefault()
                resetFilterEvent()
                dispatch(resetFilter())
            }}
        >
            Reset
        </Styles.NarrowButton>
    )
}
