import {useTheme} from 'styled-components'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {ContentContainerBottom} from '../../../../templates/fixed-page/content-container-bottom.styled'
import {ContentContainerMiddle} from '../../../../templates/fixed-page/content-container-middle.styled'
import {ActionBar} from '../action-bar/action-bar'
import {Footer} from '../footer/footer.styled'
import {Pagination} from '../footer/paging/pagination'
import {RecordSet} from '../footer/record-set/record-set'
import {ReportsCardsContent} from './reports-cards-content'
import * as Styles from './_styled/reports-cards-populated.styled'

export function ReportsCardsFormat(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()

    return (
        <Styles.ReportsCards>
            <ActionBar />
            <ContentContainerMiddle
                width={width}
                scrollable
                backgroundColor={theme.reports.dataArea.background}
            >
                <ReportsCardsContent />
            </ContentContainerMiddle>
            <ContentContainerBottom width={width}>
                <Footer width={width}>
                    <Pagination />
                    <RecordSet />
                </Footer>
            </ContentContainerBottom>
        </Styles.ReportsCards>
    )
}
