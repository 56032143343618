import styled from 'styled-components'

export const SelectedPage = styled.span`
    display: flex;
    font-weight: 800;
    background-color: #1f88e5;
    color: white;
    border-radius: 4px;
    height: 27px;
    width: 27px;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
`
