import {GuidType} from '../../../values/generic-type-defintions'
import {addEvent} from '../add-event'

export function selectShipManagerEvent(
    currentShipManager: GuidType | undefined,
    newShipManager: GuidType,
): boolean {
    return addEvent('reportsPageSelectShipManager', {
        currentShipManager: getShipManager(currentShipManager),
        newShipManager: getShipManager(newShipManager),
    })
}
function getShipManager(shipManager: GuidType | undefined): string {
    if (shipManager == undefined) {
        return 'Unknown'
    }

    return shipManager === '' ? 'All Managers' : 'Selected Manager'
}
