import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

interface HeaderLayoutProps {
    headerSize: boolean
}
export const CssGrid = styled.header<HeaderLayoutProps>`
    display: grid;
    grid-template-columns: 90px 150px;
    row-gap: ${spacing(1)};
    align-items: center;
    margin: ${(props) => (props.headerSize ? `0 0 ${spacing(8)} ${spacing(4)}` : 0)};
`

export const LogoCell = styled.div`
    grid-column: 1;
    grid-row: 1;
    display: flex;
    justify-content: center;
`

export const NameCell = styled.div`
    grid-column: 2;
    grid-row: 1;
    align-self: center;
`
export const DomainCell = styled.div`
    grid-column: 2;
    grid-row: 2;
    margin-left: ${spacing(3)};
`
export const ByTextCell = styled.div`
    grid-column: 1;
    grid-row: 2;
`
