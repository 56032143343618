import {CyberOwlTheme} from './theme'
import {Colors} from './colours'

export const LightTheme: CyberOwlTheme = {
    colors: {
        text: {
            default: Colors.grey5,
            deemphasize: Colors.grey2,
            error: Colors.red4,
            warning: Colors.red3,
            whiteText: Colors.white,
        },
        background: {
            selectedLocationPage: Colors.grey13,
            selectedPage: Colors.blue2,
            page: Colors.grey1,
            default: Colors.white,
            lowContrast: Colors.grey6,
            whiteBackground: Colors.white,
        },
        boxShadow: {
            highContrast: '#00000034',
        },
        link: {
            primary: Colors.blue2,
            disabled: Colors.grey3,
        },
        border: {
            lowContrast: Colors.grey2,
        },
        newIndicator: {
            text: Colors.red3,
            flash: Colors.red4,
            background: Colors.red5,
        },
    },
    login: {
        error: {
            errorBorder: Colors.red3,
            errorBackground: Colors.red3_21percent,
            errorContent: Colors.white,
        },
        success: {
            loginBorder: Colors.white,
            loginBackground: Colors.teal,
            loginContent: Colors.white,
            actionsFooter: 'rgba(255, 255, 255, 0.5)',
        },
    },
    loadingPage: {
        background: Colors.grey1,
    },
    navigationResponsive: {
        dataArea: {
            background: Colors.grey7,
            miniBackground: Colors.grey5,
            locationExpandedArea: Colors.grey4,
            textColor: Colors.grey1,
            miniTextColor: Colors.grey11,
        },
        active: {
            background: Colors.red3,
            underLine: Colors.orange2,
        },
    },

    reports: {
        dataArea: {
            background: Colors.grey6,
            boxShadow: 'rgba(0, 0, 0, 0.3)',
            borderLeft: Colors.blue2,
        },
        resetButton: {
            text: Colors.white,
            background: Colors.blue2,
        },
        narrowButton: {
            default: {
                backgroundColor: 'default',
                textColor: Colors.blue2,
            },
            hover: {
                backgroundColor: Colors.blue2,
                textColor: Colors.white,
            },
        },
        dataRow: {
            text: Colors.grey5,
            background: Colors.white,
            separator: Colors.grey1,
        },
        starRating: {
            selected: {
                fill: Colors.white,
                background: Colors.blue2,
            },
            unSelected: {
                fill: Colors.grey5,
                background: Colors.white,
            },
            hover: {
                fill: Colors.blue2,
                background: Colors.blue6,
            },
            staticIcon: {
                selected: Colors.grey5,
                unSelected: Colors.grey2,
            },
        },
        actionButton: {
            text: Colors.blue2,
            background: Colors.white,
            border: Colors.blue2,
        },
        filterBar: {
            boxShadow: 'rgba(0, 0, 0, 0.16)',
            closeButton: {
                text: Colors.white,
                background: Colors.blue2,
                border: Colors.blue2,
            },
        },
    },
    pagingButton: {
        clickable: {
            default: {
                background: 'inherit',
                text: Colors.grey5,
                border: Colors.grey5,
            },
            hover: {
                background: Colors.blue6,
                text: Colors.blue2,
                border: Colors.blue2,
            },
            active: {
                background: Colors.blue4,
                text: Colors.white,
                border: Colors.blue5,
            },
        },
        disabled: {
            background: 'inherit',
            text: Colors.grey2,
            border: Colors.grey2,
        },
        selected: {
            background: Colors.blue2,
            text: Colors.white,
        },
    },
    refreshButton: {
        default: {
            borderColor: 'transparent',
            backgroundColor: 'default',
            textColor: Colors.blue2,
        },
        hover: {
            borderColor: 'transparent',
            backgroundColor: Colors.blue2,
            textColor: Colors.white,
        },
        active: {
            borderColor: Colors.blue5,
            backgroundColor: Colors.blue2,
            textColor: Colors.white,
        },
        disabled: {
            borderColor: '#transparent',
            backgroundColor: 'default',
            textColor: Colors.white,
        },
    },

    font: {
        family: "'Open Sans', sans-serif",
        weight: {
            light: 300,
            normal: 400,
            semibold: 600,
            bold: 700,
            extrabold: 800,
        },
    },
}
