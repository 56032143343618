import styled from 'styled-components'

export const FixedPageSizeContainer = styled.div`
    background-color: ${(props) => props.theme.colors.background.page};
    height: 100vh;
    max-height: 100vh;
    min-height: 200px;
    display: flex;
    flex-direction: column;
`
