enum ActionType {
    SET_SELECTED_RATING = '[REPORTS FILTERS] SET SELECTED RATING',
    SET_SELECTED_TIME_RANGE_FROM = '[REPORTS FILTERS] SET SELECTED TIME RANGE FROM',
    SET_SELECTED_TIME_RANGE_TO = '[REPORTS FILTERS] SET SELECTED TIME RANGE TO',
    SET_LOCATION = '[REPORTS FILTERS] SET LOCATION',
    TOGGLE_VESSEL = '[REPORTS FILTERS] TOGGLE VESSEL',
    TOGGLE_ALL_VESSELS = '[REPORTS FILTERS] TOGGLE ALL VESSELS',
    SET_ALL_VESSELS = '[REPORTS FILTERS] SET ALL VESSELS',
    SET_SELECTED_REPORT_TYPE = '[REPORTS FILTERS] SET SELECTED REPORT TYPE',
    SET_SELECTED_BASAL_GANGLIA_ENV = '[REPORTS FILTERS] SET SELECTED BASAL GANGLIA ENV',
    RESET_FILTER = '[REPORTS FILTERS] RESET FILTER',
    TOGGLE_WHOLE_FLEET_REPORTS = '[REPORTS FILTERS] TOGGLE WHOLE FLEET REPORTS',
}

export default ActionType
