import ActionType from './action-type'
import * as Actions from './actions' // import { CustomerPortalUIConfig } from '../../values/CustomerPortalUIConfig';
import AppState from '../../types/app-state'
import {Api} from '../../../api/Api'
import {ThunkAction} from 'redux-thunk'
import {ShipOwnerUIConfig} from '../../../values/ShipOwnerUIConfig'

export function requestConfig(): Actions.RequestConfigAction {
    return {
        type: ActionType.REQUEST_CONFIG,
    }
}

export function configRetrieved(config: ShipOwnerUIConfig): Actions.ConfigRetrievedAction {
    return {
        type: ActionType.RECEIVE_CONFIG,
        payload: config,
    }
}

export function configError(reason: string): Actions.ConfigErrorAction {
    return {
        type: ActionType.CONFIG_ERROR,
        payload: reason,
    }
}

export function fetchConfig(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch, _, api: Api): void => {
        dispatch(requestConfig())

        api.fetchNotAuth(
            '/api/v1/config',
            (config) => {
                dispatch(configRetrieved(config as ShipOwnerUIConfig))
            },
            (reason) => {
                dispatch(configError(reason))
            },
        )
    }
}

export function setShowBetaPage(value: boolean): Actions.ShowBetaPageAction {
    return {
        type: ActionType.SHOW_BETA_PAGE,
        payload: value,
    }
}
