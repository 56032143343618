import {PagedReportsState} from './paged-reports-state'
import LoadingState from '../../../../values/loading-state-enum'
import {CustomerReports} from '../../../../values/customer-reports'

export const DEFAULT_PAGED_REPORTS_STATE: PagedReportsState = {
    loading: LoadingState.NotPopulated,
    dataReportsMap: new Map<number, CustomerReports[]>(),
    earliestPeriodEndDate: '',
    latestPeriodEndDate: '',
    rating: 101,
    timeRangeFrom: undefined,
    timeRangeTo: undefined,
    selectedReportType: '',
    locations: undefined,
    totalNumberOfReports: undefined,
    totalNumberOfPages: undefined,
    pageSize: 10,
    selectedPage: 0,
    allReportTypes: undefined,
    basalGangliaEnvironments: undefined,
    includeWholeFleetReports: true,
} as PagedReportsState
