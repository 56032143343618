import {useDispatch} from 'react-redux'
import {TriStateButton} from '../../../../../components/form-elements/tri-state-button/tri-state-button'
import {TriStateButtonState} from '../../../../../components/form-elements/tri-state-button/tri-state-button-state'
import {viewWholeFleetReportsClickedEvent} from '../../../../../core/google-tag-manager/reports/view-whole-fleet-reports-clicked-event'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {toggleWholeFleetReports} from '../../../../../store/state/report-filters/action-creators'
import {reportsFilterWholeReportsSelector} from '../../../../../store/state/report-filters/selectors'
import * as Styles from './whole-fleet-reports.styled'

export function WholeFleetReports(): JSX.Element {
    const dispatch = useDispatch()
    const includeWholeFleetReports = useTypedSelector(reportsFilterWholeReportsSelector)

    function determineNewState(currentState: TriStateButtonState): TriStateButtonState {
        return currentState === TriStateButtonState.FULLY_SELECTED
            ? TriStateButtonState.NOT_SELECTED
            : TriStateButtonState.FULLY_SELECTED
    }
    const viewAll = determineViewAll(includeWholeFleetReports)
    function viewAllChanged(newState: TriStateButtonState): void {
        if (newState === TriStateButtonState.NOT_SELECTED) {
            dispatch(toggleWholeFleetReports(false))
        } else if (newState === TriStateButtonState.FULLY_SELECTED) {
            dispatch(toggleWholeFleetReports(true))
        }
        viewWholeFleetReportsClickedEvent(viewAll, newState)
    }
    return (
        <Styles.Wrapper>
            <Styles.Label>Whole Fleet Reports:</Styles.Label>
            <TriStateButton
                id="whole-reports-view-all"
                label=""
                state={viewAll}
                onClick={() => viewAllChanged(determineNewState(viewAll))}
            />
        </Styles.Wrapper>
    )
}
function determineViewAll(includeWholeFleetReports: boolean): TriStateButtonState {
    if (includeWholeFleetReports) {
        return TriStateButtonState.FULLY_SELECTED
    }

    return TriStateButtonState.NOT_SELECTED
}
