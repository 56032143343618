import AppState from '../../types/app-state'
import {BasalGangliaData, BasalGangliaDataMap} from './state'

export const basalGangliaEnvSelector = (state: AppState): BasalGangliaData[] =>
    state.basalGangliaEnv.basalGangliaEnv

export const basalGangliaEnvLoadingSelector = (state: AppState): boolean =>
    state.basalGangliaEnv.isFetching
export const basalGangliaEnvMapSelector = (state: AppState): BasalGangliaDataMap =>
    state.basalGangliaEnv.basalGangliaEnvMap
