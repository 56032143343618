import {usePagedReports} from '../../contexts/use-paged-reports'
import {NoDataCard} from './no-data-card/no-data-card'
import {ReportsCardsPopulated} from './reports-cards-populated'

export function ReportsCardsLoaded(): JSX.Element {
    const {totalNumberOfReports, dataReports} = usePagedReports()

    return dataReports && totalNumberOfReports && totalNumberOfReports > 0 ? (
        <ReportsCardsPopulated customerReports={dataReports} />
    ) : (
        <NoDataCard />
    )
}
