import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {CardArea} from '../_styled/reports-cards-populated.styled'
import * as Styles from './no-data.styled'

export function NoDataCard(): JSX.Element {
    const {width} = useDimensions()

    return (
        <CardArea width={width}>
            <Styles.NoDataCardStyle>
                <Styles.NoDataMessage>No reports found.</Styles.NoDataMessage>
            </Styles.NoDataCardStyle>
        </CardArea>
    )
}
