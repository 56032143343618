import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {AdminProps} from '../../../login-page'

export const Content = styled.main`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.text.whiteText};
`

export enum DetailRightMargin {
    NARROW = '50px',
    NORMAL = '94px',
    WIDE = '154px',
}

interface DetailProps {
    rightMargin: DetailRightMargin
}

interface CallToActionSectionProps extends AdminProps {
    reducedHeight: boolean
    reducedWidth: boolean
    showError: boolean
}

export const CallToActionSection = styled.section<CallToActionSectionProps>`
    border-radius: 15px;
    width: ${(props) => (props.reducedWidth ? '250px' : '305px')};
    min-height: ${(props) => {
        if (props.admin) {
            return '0'
        }
        return props.reducedHeight ? '200px' : '280px'
    }};
    padding: ${(props) =>
        props.reducedHeight || props.reducedWidth || props.showError ? spacing(4) : spacing(7)};
    border: ${(props) =>
        props.showError ? `2px solid ${props.theme.login.error.errorBorder}` : 'none'};
    background-color: ${(props) =>
        props.showError
            ? props.theme.login.error.errorBackground
            : props.theme.login.success.loginBackground};

    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
`

export const Detail = styled.div<DetailProps>`
    margin-right: ${(props) => props.rightMargin};
`

interface MainActionsProps {
    addBottomMargin: boolean
}

export const MainActions = styled.div<MainActionsProps>`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${(props) => (props.addBottomMargin ? 'margin-bottom: 20px;' : '')}
`

interface MainActionsGridProps {
    reducedGap: boolean
}

export const MainActionsGrid = styled.div<MainActionsGridProps>`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: ${(props) => (props.reducedGap ? spacing(3) : spacing(7))};
    margin-bottom: ${spacing(4)};
`

export interface AsideWrapperProps {
    width: number
}

export const AsideWrapper = styled.div<AsideWrapperProps>`
    margin-top: ${spacing(6)};
    width: ${(props) => (props.width > 1080 ? '536px' : props.width > 420 ? '400px' : '100%')};
`
