import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
export const ComplianceContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${spacing(4)};
`
export const Label = styled.label`
    font-size: 14px;
    margin-bottom: 0;
`
export const StarsBox = styled.div`
    display: flex;
`
