import {GuidType} from '../../../values/generic-type-defintions'

export interface BasalGangliaData {
    Identity: string
    Name: string
    Url: string
}
export type BasalGangliaDataMap = Map<GuidType, BasalGangliaData>

export interface BasalGangliaEnvReduxState {
    isFetching: boolean
    basalGangliaEnv: BasalGangliaData[]
    basalGangliaEnvMap: BasalGangliaDataMap
    error: string
}

export const DEFAULT_BASAL_GANGLIA_ENV_STATE: BasalGangliaEnvReduxState = {
    isFetching: true,
    basalGangliaEnv: [],
    basalGangliaEnvMap: new Map<GuidType, BasalGangliaData>(),
    error: '',
}
