import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import * as Styles from './_styles/nav-bar-link.styled'

interface NavBarLinkProps {
    id: string
    path: string
    navLinkText: string
    showIndicator?: boolean
    gtmLogger: (() => void) | null
}

export function NavBarLink({
    id,
    path,
    navLinkText,
    showIndicator,
    gtmLogger = null,
}: NavBarLinkProps): JSX.Element {
    const {width} = useDimensions()
    function menuClicked() {
        if (gtmLogger) {
            gtmLogger()
        }
    }

    return (
        <Styles.NavigationLink
            id={id}
            to={path}
            activeClassName="activeLink"
            width={width}
            onClick={menuClicked}
        >
            {navLinkText}
            {showIndicator && <Styles.IndicatorDot />}
        </Styles.NavigationLink>
    )
}
