import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {GetMoreButton} from '../../actions/get-more-button'
import {SignInButton} from '../../actions/sign-in-button'
import {ActionsFooter} from '../../actions/actions-footer'
import * as Styled from './column-based-layout.styled'
import {
    ColumnBasedLoginLayout,
    needsReducedHeight,
    needsReducedWidth,
} from './column-based-login-layout'
import {Cargo} from '../../actions/styles/cargo'
import {AdminProps} from '../../../login-page'

export function LoginPanel(props: AdminProps): JSX.Element {
    const {width, height} = useDimensions()

    const reducedHeight = needsReducedHeight(height)
    const reducedWidth = needsReducedWidth(width)

    return (
        <ColumnBasedLoginLayout admin={props.admin}>
            {!props.admin && (
                <Styled.MainActions addBottomMargin={reducedHeight}>
                    <Styled.MainActionsGrid reducedGap={reducedHeight}>
                        <>
                            <SignInButton />
                            <GetMoreButton smallText={reducedWidth || reducedHeight} />
                        </>
                    </Styled.MainActionsGrid>
                    <Cargo />
                </Styled.MainActions>
            )}
            <ActionsFooter admin={props.admin} />
        </ColumnBasedLoginLayout>
    )
}
