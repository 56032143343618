import PersistableAppState from './persistable-app-state'
import {RouterState} from 'connected-react-router'
import {ConfigReduxState} from '../state/config/state'
import {RolesReduxState} from '../state/roles/state'
import {UsersReduxState} from '../state/users/state'
import {SessionDataReduxState} from '../state/session-data/state'
import {LocationState} from 'history'
import {CurrentUserReduxState} from '../state/current-user/state'
import {LocationsReduxState} from '../state/locations/state'
import {ReportsFiltersReduxState} from '../state/report-filters/state'
import {BasalGangliaEnvReduxState} from '../state/basal-ganglia-env/state'

interface AppState extends PersistableAppState {
    router?: RouterState<LocationState>
    roles: RolesReduxState
    users: UsersReduxState
    sessionData: SessionDataReduxState
    config: ConfigReduxState
    currentUser: CurrentUserReduxState
    locations: LocationsReduxState
    reportsFilter: ReportsFiltersReduxState
    basalGangliaEnv: BasalGangliaEnvReduxState
}

export function toPersistableAppState(state: AppState): PersistableAppState {
    return {
        auth: state.auth,
    }
}

export default AppState
