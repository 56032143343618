import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import MiscDocument from '../../../../../@assets/images/icon-misc-document.png'
interface DownloadIconProps {
    contentType: string
}

export function DownloadIcon({contentType}: DownloadIconProps): JSX.Element | null {
    if (contentType == undefined || contentType == null) {
        return null
    }
    if (contentType.toLowerCase().startsWith('application/vnd')) {
        return <Icon glyph="Excel" height={19} width={19} />
    }
    if (contentType.toLowerCase() === 'application/csv') {
        return <Icon glyph="Csv" height={19} width={19} />
    }
    if (contentType.toLowerCase() === 'application/pdf') {
        return <Icon glyph="Pdf" height={19} width={19} />
    }
    return <img src={MiscDocument} height={19} width={17} />
}
