import {useTheme} from 'styled-components'
import {CyberOwlTheme} from '../../../../../theme/theme'

interface StarIconProps {
    selected: boolean
    isHovering: boolean
}
function getFillColor(theme: CyberOwlTheme, selected: boolean, isHovering: boolean): string {
    if (!selected) {
        return theme.reports.starRating.unSelected.fill
    }
    return isHovering ? theme.reports.starRating.hover.fill : theme.reports.starRating.selected.fill
}
export function StarIcon({selected, isHovering}: StarIconProps): JSX.Element {
    const theme = useTheme()
    return (
        <svg width={12} height={19} preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 15">
            <path
                d="M8,0l2,5.578,6,.151-4.764,3.6L12.944,15,8,11.646,3.056,15,4.764,9.328,0,5.729l6-.151Z"
                fill={getFillColor(theme, selected, isHovering)}
            />
        </svg>
    )
}
