import {ButtonData, ButtonType} from './button-data'
import {ShowPagingButtons} from './show-buttons'

function getPage(
    pageIndex: number,
    selectedPage: number | undefined,
    endNumber = false,
): ButtonData {
    return {
        type: endNumber ? ButtonType.EndNumeric : ButtonType.Numeric,
        pageIndex,
        text: (pageIndex + 1).toFixed(),
        selected: pageIndex === selectedPage,
    } as ButtonData
}

function getNewDownNPagesIndex(selectedPage: number | undefined, numberOfPages: number): number {
    if (selectedPage == undefined) {
        return 0
    }

    const newSelectedPage = selectedPage - numberOfPages
    return newSelectedPage < 0 ? 0 : newSelectedPage
}

function getNewDownPageIndex(
    totalNumberOfPages: number,
    selectedPage: number | undefined,
    availablePages: {end: number; middle: number},
): number {
    if (selectedPage == undefined) {
        return 0
    }

    if (selectedPage >= totalNumberOfPages - availablePages.end) {
        const newStartIndex = totalNumberOfPages - (availablePages.end + availablePages.middle)
        const newSelectedPage = newStartIndex + Math.floor(availablePages.middle / 2)
        return newSelectedPage < 0 ? 0 : newSelectedPage
    }

    const newSelectedPage = selectedPage - availablePages.middle
    return newSelectedPage < 0 ? 0 : newSelectedPage
}

function getDownDots(pageIndex: number): ButtonData {
    return {
        type: ButtonType.DownDots,
        pageIndex,
    } as ButtonData
}

function getDownPage(pageIndex: number, selectedPage: number | undefined): ButtonData {
    if (selectedPage == undefined || selectedPage === 0) {
        return {type: ButtonType.DownPage, disabled: true, text: '<'} as ButtonData
    }

    return {
        type: ButtonType.DownPage,
        pageIndex,
    } as ButtonData
}

function getDownByOffsetButton(
    selectedPage: number | undefined,
    buttonType: ButtonType,
    buttonText: string,
    newPageIndex: number,
): ButtonData {
    if (selectedPage == undefined || selectedPage === 0) {
        return {type: buttonType, disabled: true, text: buttonText} as ButtonData
    }

    return {
        type: buttonType,
        pageIndex: newPageIndex,
        text: buttonText,
    } as ButtonData
}

function getNewUpNPagesIndex(
    totalNumberOfPage: number,
    selectedPage: number | undefined,
    numberOfPages: number,
): number {
    if (selectedPage == undefined) {
        return totalNumberOfPage - 1
    }

    const newSelectedPage = selectedPage + numberOfPages
    return newSelectedPage > totalNumberOfPage - 1 ? totalNumberOfPage - 1 : newSelectedPage
}

function getNewUpPageIndex(
    totalNumberOfPages: number,
    selectedPage: number | undefined,
    availablePages: {end: number; middle: number},
): number {
    if (selectedPage == undefined) {
        return totalNumberOfPages - 1
    }

    if (selectedPage < availablePages.end) {
        const newStartIndex = availablePages.end
        const newSelectedPage = newStartIndex + Math.floor(availablePages.middle / 2)
        return newSelectedPage > totalNumberOfPages - 1 ? totalNumberOfPages - 1 : newSelectedPage
    }

    const newSelectedPage = selectedPage + availablePages.middle
    return newSelectedPage > totalNumberOfPages - 1 ? totalNumberOfPages - 1 : newSelectedPage
}

function getUpDots(pageIndex: number): ButtonData {
    return {
        type: ButtonType.UpDots,
        pageIndex,
    } as ButtonData
}

function getUpPage(
    pageIndex: number,
    selectedPage: number | undefined,
    totalNumberOfPages: number,
): ButtonData {
    if (selectedPage == undefined || selectedPage === totalNumberOfPages - 1) {
        return {type: ButtonType.UpPage, disabled: true, text: '>'} as ButtonData
    }

    return {
        type: ButtonType.UpPage,
        pageIndex,
    } as ButtonData
}

function getUpByOffsetButton(
    selectedPage: number | undefined,
    totalNumberOfPage: number,
    buttonType: ButtonType,
    buttonText: string,
    newPageIndex: number,
): ButtonData {
    if (selectedPage == undefined || selectedPage === totalNumberOfPage - 1) {
        return {type: buttonType, disabled: true, text: buttonText} as ButtonData
    }

    return {
        type: buttonType,
        pageIndex: newPageIndex,
        text: buttonText,
    } as ButtonData
}

function buildForAllPagesShown(
    selectedPage: number | undefined,
    totalNumberOfPages: number,
): ButtonData[] {
    const buttons: ButtonData[] = []

    for (let i = 0; i < totalNumberOfPages; i++) {
        buttons.push(getPage(i, selectedPage))
    }

    return buttons
}

function addJumpDownButtons(
    buttons: ButtonData[],
    totalNumberOfPages: number,
    pageSize: number | undefined,
    selectedPage: number | undefined,
    addDownDots: boolean,
    availablePages: {end: number; middle: number},
    show100PagesJump?: boolean,
    show10PagesJump?: boolean,
    showJump?: boolean,
    showEndNumbers?: boolean,
): void {
    if (show100PagesJump) {
        buttons.push(
            getDownByOffsetButton(
                selectedPage,
                ButtonType.LargestNumeric,
                `-${(pageSize || 0) * 100}`,
                getNewDownNPagesIndex(selectedPage, 100),
            ),
        )
    }

    if (show10PagesJump) {
        buttons.push(
            getDownByOffsetButton(
                selectedPage,
                ButtonType.LargerNumeric,
                `-${(pageSize || 0) * 10}`,
                getNewDownNPagesIndex(selectedPage, 10),
            ),
        )
    }

    const newPageIndex = getNewDownPageIndex(totalNumberOfPages, selectedPage, availablePages)

    if (showJump) {
        buttons.push(getDownPage(newPageIndex, selectedPage))
    }

    if (showEndNumbers) {
        buttons.push(getPage(0, selectedPage, true))
    }

    if (addDownDots) {
        buttons.push(getDownDots(newPageIndex))
    }
}

function addJumpUpButtons(
    buttons: ButtonData[],
    totalNumberOfPages: number,
    pageSize: number | undefined,
    selectedPage: number | undefined,
    addUpDots: boolean,
    availablePages: {end: number; middle: number},
    show100PagesJump: boolean,
    show10PagesJump: boolean,
    showJump: boolean,
    showEndNumbers: boolean,
): void {
    const newPageIndex = getNewUpPageIndex(totalNumberOfPages, selectedPage, availablePages)

    if (addUpDots) {
        buttons.push(getUpDots(newPageIndex))
    }

    if (showEndNumbers) {
        buttons.push(getPage(totalNumberOfPages - 1, -1, true))
    }

    if (showJump) {
        buttons.push(getUpPage(newPageIndex, selectedPage, totalNumberOfPages))
    }

    if (show10PagesJump) {
        buttons.push(
            getUpByOffsetButton(
                selectedPage,
                totalNumberOfPages,
                ButtonType.LargerNumeric,
                `+${(pageSize || 0) * 10}`,
                getNewUpNPagesIndex(totalNumberOfPages, selectedPage, 10),
            ),
        )
    }

    if (show100PagesJump) {
        buttons.push(
            getUpByOffsetButton(
                selectedPage,
                totalNumberOfPages,
                ButtonType.LargestNumeric,
                `+${(pageSize || 0) * 100}`,
                getNewUpNPagesIndex(totalNumberOfPages, selectedPage, 100),
            ),
        )
    }
}

function addNumericButtons(
    buttons: ButtonData[],
    startIndex: number,
    endIndex: number,
    selectedPage: number | undefined,
): void {
    for (let i = startIndex; i < endIndex; i++) {
        buttons.push(getPage(i, selectedPage))
    }
}

function buildForAtStartSomePagesHidden(
    selectedPage: number | undefined,
    totalNumberOfPages: number,
    pageSize: number | undefined,
    showButtons: ShowPagingButtons,
    availablePages: {end: number; middle: number},
): ButtonData[] {
    const buttons: ButtonData[] = []

    addJumpDownButtons(
        buttons,
        totalNumberOfPages,
        pageSize,
        selectedPage,
        false,
        availablePages,
        showButtons.jump100Pages,
        showButtons.jump10Pages,
        showButtons.arrows,
        false,
    )
    addNumericButtons(buttons, 0, availablePages.end, selectedPage)
    addJumpUpButtons(
        buttons,
        totalNumberOfPages,
        pageSize,
        selectedPage,
        true,
        availablePages,
        showButtons.jump100Pages || false,
        showButtons.jump10Pages || false,
        showButtons.arrows || false,
        showButtons.endNumbers || false,
    )

    return buttons
}

function buildForAtEndSomePagesHidden(
    selectedPage: number | undefined,
    totalNumberOfPages: number,
    pageSize: number | undefined,
    showButtons: ShowPagingButtons,
    availablePages: {end: number; middle: number},
): ButtonData[] {
    const buttons: ButtonData[] = []

    const startPage = totalNumberOfPages - availablePages.end
    addJumpDownButtons(
        buttons,
        totalNumberOfPages,
        pageSize,
        selectedPage,
        true,
        availablePages,
        showButtons.jump100Pages || false,
        showButtons.jump10Pages || false,
        showButtons.arrows || false,
        showButtons.endNumbers || false,
    )

    addNumericButtons(buttons, startPage, totalNumberOfPages, selectedPage)
    addJumpUpButtons(
        buttons,
        totalNumberOfPages,
        pageSize,
        selectedPage,
        false,
        availablePages,
        showButtons.jump100Pages || false,
        showButtons.jump10Pages || false,
        showButtons.arrows || false,
        false,
    )

    return buttons
}

function buildForInMiddleSomePagesHidden(
    selectedPage: number | undefined,
    totalNumberOfPages: number,
    pageSize: number | undefined,
    showButtons: ShowPagingButtons,
    availablePages: {end: number; middle: number},
): ButtonData[] {
    const buttons: ButtonData[] = []

    const startPage =
        selectedPage != undefined ? selectedPage - Math.floor(availablePages.middle / 2) : 0

    addJumpDownButtons(
        buttons,
        totalNumberOfPages,
        pageSize,
        selectedPage,
        true,
        availablePages,
        showButtons.jump100Pages || false,
        showButtons.jump10Pages || false,
        showButtons.arrows || false,
        showButtons.endNumbers || false,
    )

    const upperBound = startPage + availablePages.middle
    addNumericButtons(buttons, startPage, upperBound, selectedPage)
    addJumpUpButtons(
        buttons,
        totalNumberOfPages,
        pageSize,
        selectedPage,
        true,
        availablePages,
        showButtons.jump100Pages || false,
        showButtons.jump10Pages || false,
        showButtons.arrows || false,
        showButtons.endNumbers || false,
    )

    return buttons
}

function ensureOddNumberOfPages(
    inMiddlePages: boolean,
    availablePages: number,
    numberOfActionButtons: number,
): number {
    if (inMiddlePages) {
        return availablePages % 2 === 0 ? availablePages - 1 : availablePages
    }

    if (numberOfActionButtons % 2 == 0) {
        return availablePages % 2 === 0 ? availablePages - 1 : availablePages
    } else {
        return availablePages % 2 !== 0 ? availablePages - 1 : availablePages
    }
}

function determineHowManyNumericButtonsCanBeShown(
    pagesToShow: number,
    showButtons: ShowPagingButtons,
    inMiddleOfPages: boolean,
): {showButtons: ShowPagingButtons; availablePages: number} {
    let acceptAnyway = false

    const minimumNumericButtonsNeeded = inMiddleOfPages ? 3 : 4
    while (true) {
        let availablePages = pagesToShow
        let numberOfActionButtons = 0
        if (showButtons.jump100Pages) {
            // the jump 100 pages button is 2x size the standard button
            // so we count that we are showing 2 action buttons,
            // but we need to deduct 4 buttons from space to show
            numberOfActionButtons = numberOfActionButtons + 2
            availablePages = availablePages - 4
        }

        if (showButtons.jump10Pages) {
            // the jump 10 pages button is 1.5x size the standard button
            // so we count that we are showing 2 action buttons,
            // but we need to deduct 3 buttons from space to show
            numberOfActionButtons = numberOfActionButtons + 2
            availablePages = availablePages - 3
        }

        if (showButtons.arrows) {
            numberOfActionButtons = numberOfActionButtons + 2
            availablePages = availablePages - 2
        }

        if (showButtons.endNumbers) {
            // if you're at start/end of pages you onnly show one end button
            // you only show both when in the middle of pages
            numberOfActionButtons = numberOfActionButtons + (inMiddleOfPages ? 2 : 1)
            availablePages = availablePages - (inMiddleOfPages ? 2 : 1)
        }

        // up/down dots (always shown)
        numberOfActionButtons = numberOfActionButtons + (inMiddleOfPages ? 2 : 1)
        availablePages = availablePages - (inMiddleOfPages ? 2 : 1)

        if (availablePages >= minimumNumericButtonsNeeded || acceptAnyway) {
            return {
                showButtons,
                availablePages: ensureOddNumberOfPages(
                    inMiddleOfPages,
                    availablePages,
                    numberOfActionButtons,
                ),
            }
        }

        if (showButtons.jump100Pages) {
            showButtons.jump100Pages = false
        } else if (showButtons.jump10Pages) {
            showButtons.jump10Pages = false
        } else if (showButtons.arrows) {
            showButtons.arrows = false
        } else if (showButtons.endNumbers) {
            showButtons.endNumbers = false
        } else {
            acceptAnyway = true
        }
    }
}

function selectedPageAtStart(selectedPage: number | undefined, availablePages: number): boolean {
    return selectedPage != undefined && selectedPage <= Math.ceil(availablePages / 2)
}

function selectedPageAtEnd(
    selectedPage: number | undefined,
    totalNumberOfPages: number,
    availablePages: number,
): boolean {
    return (
        selectedPage != undefined &&
        selectedPage >= Math.floor(totalNumberOfPages - availablePages / 2) - 1
    )
}

export function buildButtons(
    selectedPage: number | undefined,
    totalNumberOfPages: number,
    pagesToShow: number,
    pageSize: number | undefined,
    showButtons: ShowPagingButtons,
): ButtonData[] {
    if (totalNumberOfPages <= pagesToShow) {
        return buildForAllPagesShown(selectedPage, totalNumberOfPages)
    }

    const configForEndPageScenario = determineHowManyNumericButtonsCanBeShown(
        pagesToShow,
        showButtons,
        false,
    )

    const configForMiddlePageScenario = determineHowManyNumericButtonsCanBeShown(
        pagesToShow,
        configForEndPageScenario.showButtons,
        true,
    )

    if (selectedPageAtStart(selectedPage, configForEndPageScenario.availablePages)) {
        return buildForAtStartSomePagesHidden(
            selectedPage,
            totalNumberOfPages,
            pageSize,
            configForEndPageScenario.showButtons,
            {
                end: configForEndPageScenario.availablePages,
                middle: configForMiddlePageScenario.availablePages,
            },
        )
    }

    if (
        selectedPageAtEnd(selectedPage, totalNumberOfPages, configForEndPageScenario.availablePages)
    ) {
        return buildForAtEndSomePagesHidden(
            selectedPage,
            totalNumberOfPages,
            pageSize,
            configForEndPageScenario.showButtons,
            {
                end: configForEndPageScenario.availablePages,
                middle: configForMiddlePageScenario.availablePages,
            },
        )
    }

    return buildForInMiddleSomePagesHidden(
        selectedPage,
        totalNumberOfPages,
        pageSize,
        configForMiddlePageScenario.showButtons,
        {
            end: configForMiddlePageScenario.availablePages,
            middle: configForMiddlePageScenario.availablePages,
        },
    )
}
