import * as Styled from './_styled/logo-container.styled'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
export function LogoContainer(): JSX.Element {
    return (
        <Styled.LeftContainerStyle>
            <Styled.LogoStyle>
                <Icon glyph="Medulla" />
            </Styled.LogoStyle>
        </Styled.LeftContainerStyle>
    )
}
