import {FC, Fragment} from 'react'

import {RoleCheckerProps} from './types'

import usedTypeSelector from '../../hooks/use-typed-selector'
import {isUserAllowedCachedReselector} from './is-user-allowed-reselector'

const RoleChecker: FC<RoleCheckerProps> = ({rolesAllowed, children}) => {
    if (usedTypeSelector((state) => isUserAllowedCachedReselector(state, rolesAllowed))) {
        return null
    }

    return <Fragment>{children}</Fragment>
}

export default RoleChecker
