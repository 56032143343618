import Axios, {AxiosInstance} from 'axios'
import {store} from '../../index'
import {errorOccured} from '../../store/state/auth/action-creators'

const EXPIRED_SESSION = 'Session expired'
const UNAUTHORIZED = 401

export const makeAxios = (): Promise<AxiosInstance> =>
    // load config
    fetch(
        `${process.env.PUBLIC_URL}/config/${
            process.env.REACT_APP_ALT_BACKEND || 'ship-owners-ui'
        }.json`,
    )
        .then((response) => response.json())
        .then((json) =>
            // create axios
            Axios.create({
                baseURL: json.baseUrl,
                timeout: 5000,
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        )
        .then((axiosInstance) => {
            // interceptor to add auth token
            axiosInstance.interceptors.request.use((config) => {
                if (config.headers) {
                    config.headers.Authorization = `Bearer ${store.getState().auth.authToken}`
                }
                return config
            })

            axiosInstance.interceptors.response.use(
                (response) => response,
                (error) => {
                    if (error && error.response && error.response.status === UNAUTHORIZED) {
                        store.dispatch(errorOccured(EXPIRED_SESSION))

                        return Promise.reject(error)
                    } else {
                        return Promise.reject(error)
                    }
                },
            )

            return axiosInstance
        })
