import * as Styles from './_styles/logout.styled'
import {LogOut} from 'react-feather'
import {loggingOut} from '../../../../store/state/session-data/action-creators'
import {useDispatch} from 'react-redux'
import {logoutEvent} from '../../../../core/google-tag-manager/navigation/log-out-event'

export function Logout(): JSX.Element {
    const dispatch = useDispatch()
    return (
        <Styles.Button
            id="logout-button"
            onClick={() => {
                logoutEvent()
                dispatch(loggingOut())
            }}
        >
            <LogOut />
        </Styles.Button>
    )
}
