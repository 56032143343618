/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Store} from 'redux'

import AppState from '../store/types/app-state'
import {errorOccured} from '../store/state/auth/action-creators'
import {loggingOut} from '../store/state/session-data/action-creators'

interface ConfigProps {
    baseUrl: string
    baseWsUrl: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Callback = (data: any) => void

const UNAUTHORIZED = 401
const THROW_ERROR: Callback = (reason: string) => {
    throw new Error(reason)
}
export const UNKNOWN_USER = 'You are are not registered. Please contact administrator.'
const EXPIRED_SESSION = 'Session expired'

function loginErrorMessage(error: ErrorMessage): string {
    switch (error.message.message) {
        case 'unable to login (user)':
        case 'the logged in user is not authorized to access this service':
            return UNKNOWN_USER
        default:
            return EXPIRED_SESSION
    }
}

interface ErrorMessage {
    code: number
    message: any
}

export class Api {
    private store?: Store<AppState>
    private initialised = false

    private configPromise: Promise<ConfigProps>

    public constructor() {
        this.configPromise = fetch(
            `${process.env.PUBLIC_URL}/config/${
                process.env.REACT_APP_ALT_BACKEND || 'ship-owners-ui'
            }.json`,
        )
            .then((config) => config.json())
            .then((config) => config as ConfigProps)
        this.getJsonIfOk = this.getJsonIfOk.bind(this)
    }

    public getAuth(url: string, callback: Callback, errorCallback?: Callback): void {
        this.fetchAuth(url, 'get', callback, errorCallback)
    }

    public deleteAuth(url: string, callback: Callback, errorCallback?: Callback): void {
        this.fetchAuth(url, 'delete', callback, errorCallback)
    }

    public postAuth(url: string, callback: Callback, errorCallback?: Callback, body?: any): void {
        this.fetchAuth(url, 'post', callback, errorCallback, body)
    }

    public putAuth(url: string, callback: Callback, errorCallback?: Callback, body?: any): void {
        this.fetchAuth(url, 'put', callback, errorCallback, body)
    }

    public fetchAuth(
        url: string,
        method = 'get',
        callback: Callback,
        errorCallback?: Callback,
        body?: any,
    ): void {
        const errCallback: Callback = errorCallback ? errorCallback : THROW_ERROR

        this.fetchWithAuth(url, method, body)
            .then(this.getJsonIfOk)
            .then(callback)
            .catch((reason) => {
                if (reason.code === UNAUTHORIZED || reason.code === 403) {
                    const loginError = loginErrorMessage(reason)
                    this.redirectToLogin(loginError || 'Unspecified Error')
                } else if (reason === UNAUTHORIZED) {
                    this.redirectToLogin('Unspecified Error')
                } else {
                    errCallback(reason)
                }
            })
    }

    public fetchNotAuth(url: string, callback: Callback, errorCallback?: Callback): void {
        const errCallback: Callback = errorCallback ? errorCallback : THROW_ERROR

        this.configPromise.then((config) =>
            fetch(config.baseUrl + url)
                .then(this.getJsonIfOk)
                .then(callback)
                .catch((reason) => {
                    errCallback(reason)
                }),
        )
    }

    public init(store: Store<AppState>): void {
        this.store = store
        this.initialised = true
    }
    public initWs(): void {
        const authToken: string | undefined = this.store!.getState().auth.authToken

        if (authToken == undefined) {
            return
        }
    }
    private getJsonIfOk(response: Response): PromiseLike<Response> | Response {
        const contentType = response.headers.get('Content-Type')
        const isContentTypeJson = contentType != null && contentType!.includes('application/json')

        return response.ok
            ? isContentTypeJson
                ? response.json()
                : response
            : isContentTypeJson
            ? response
                  .json()
                  .then((value) => Promise.reject({code: response.status, message: value}))
            : Promise.reject({code: response.status, message: response.statusText})
    }

    private fetchWithAuth(url: string, method = 'get', body?: any): Promise<Response> {
        if (!this.initialised) {
            throw new Error('not initialised')
        }

        if (this.store == undefined) {
            throw new Error('state initialised')
        }

        const myHeaders: Headers = new Headers()
        const authToken: string | undefined = this.store.getState().auth.authToken

        if (authToken == undefined) {
            return Promise.reject(UNAUTHORIZED)
        }

        myHeaders.append('Authorization', `Bearer ${authToken}`)
        myHeaders.append('Content-Type', 'application/json')

        const jsonBody: string | undefined = body != undefined ? JSON.stringify(body) : undefined

        return this.configPromise.then((config) =>
            fetch(config.baseUrl + url, {headers: myHeaders, method: method, body: jsonBody}),
        )
    }

    private redirectToLogin(message: string): void {
        if (message === 'Session expired') {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.store!.dispatch(loggingOut())
        } else {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.store!.dispatch(errorOccured(message))
        }
    }
}
