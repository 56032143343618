import ActionType from './action-type'
import {Action} from './actions'
import _ from 'lodash'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'

import {
    ConfigFetchingStatus,
    ConfigReduxState,
    DEFAULT_CONFIG_STATE,
    OAuthClientIdsType,
} from './state'
import {Feature, ShipOwnerUIConfig} from '../../../values/ShipOwnerUIConfig'

function isEqualArrays(
    currentArray: Feature[] | undefined,
    newArray: Feature[] | undefined,
): boolean {
    return _.isEqual(currentArray, newArray)
}

function isEqualObjects(
    cuurentObj: OAuthClientIdsType | undefined,
    newObj: OAuthClientIdsType | undefined,
): boolean {
    return _.isEqual(cuurentObj, newObj)
}

function requestConfig(currentState: ConfigReduxState): ConfigReduxState {
    if (
        isEqualArrays(currentState.features, []) &&
        currentState.trackingId === '' &&
        currentState.auth === '' &&
        currentState.preview === '' &&
        isEqualObjects(currentState.oauthClientIds, {
            google: '',
            microsoft: '',
        }) &&
        (currentState.fetchingStatus === ConfigFetchingStatus.REREQUESTING ||
            currentState.fetchingStatus === ConfigFetchingStatus.REQUESTING)
    ) {
        return currentState
    }
    return {
        ...currentState,
        splunkDashboardUrl: undefined,
        features: [],
        trackingId: '',
        auth: '',
        preview: '',
        tenantDisplayName: '',
        fetchingStatus:
            currentState.fetchingStatus === ConfigFetchingStatus.NEEDS_RESETTING
                ? ConfigFetchingStatus.REREQUESTING
                : ConfigFetchingStatus.REQUESTING,
        oauthClientIds: undefined,
    }
}

function receiveConfig(
    currentState: ConfigReduxState,
    newState: ShipOwnerUIConfig,
): ConfigReduxState {
    if (
        currentState.splunkDashboardUrl === newState.splunkDashboardUrl &&
        isEqualArrays(currentState.features, newState.features) &&
        currentState.trackingId === newState.trackingId &&
        currentState.auth === newState.auth &&
        currentState.preview === newState.preview &&
        currentState.tenantDisplayName === newState.tenantDisplayName &&
        isEqualObjects(currentState.oauthClientIds, newState.oauthClientIds) &&
        currentState.fetchingStatus === ConfigFetchingStatus.INITIALISED
    ) {
        return currentState
    }

    return {
        ...currentState,
        splunkDashboardUrl: newState.splunkDashboardUrl,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        features: newState.features!,
        trackingId: newState.trackingId,
        auth: newState.auth,
        preview: newState.preview,
        tenantDisplayName: newState.tenantDisplayName,
        fetchingStatus: ConfigFetchingStatus.INITIALISED,
        oauthClientIds: newState.oauthClientIds,
    }
}
function logout(currentState: ConfigReduxState): ConfigReduxState {
    if (
        isEqualArrays(currentState.features, []) &&
        currentState.trackingId === '' &&
        currentState.auth === '' &&
        currentState.preview === '' &&
        isEqualObjects(currentState.oauthClientIds, {
            google: '',
            microsoft: '',
        }) &&
        currentState.fetchingStatus === ConfigFetchingStatus.NEEDS_RESETTING
    ) {
        return currentState
    }
    return {
        ...DEFAULT_CONFIG_STATE,
        fetchingStatus: ConfigFetchingStatus.NEEDS_RESETTING,
    }
}
function showBetaPage(currentState: ConfigReduxState, newState = true): ConfigReduxState {
    if (currentState.showBetaPage === newState) {
        return currentState
    }
    return {
        ...currentState,
        showBetaPage: newState,
    }
}
export default function configReducer(
    state: ConfigReduxState = DEFAULT_CONFIG_STATE,
    action: Action | LoggingOutAction,
): ConfigReduxState {
    switch (action.type) {
        case ActionType.REQUEST_CONFIG:
            return requestConfig(state)
        case ActionType.RECEIVE_CONFIG:
            return receiveConfig(state, action.payload)
        case ActionType.SHOW_BETA_PAGE:
            return showBetaPage(state, action.payload)

        case SessionActionType.LOGGING_OUT:
            return logout(state)
        /* istanbul ignore next */
        default:
            return state
    }
}
