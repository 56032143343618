import * as Styles from './vessels-filter.styled'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../../../store/state/locations/selectors'
import {LocationIdType} from '../../../../../store/state/locations/state'
import {TriStateButtonState} from '../../../../../components/form-elements/tri-state-button/tri-state-button-state'
import {VesselRow} from './vessel-row'
import {ViewAllVesselsRow} from './view-all-vessels-row'
import {reportsFiltersSelector} from '../../../../../store/state/report-filters/selectors'
import {useDispatch} from 'react-redux'
import {
    toggleAllVessels,
    toggleVessel,
} from '../../../../../store/state/report-filters/action-creators'
import {clickViewAllVesselsEvent} from '../../../../../core/google-tag-manager/reports/click-view-all-vessels-event'
import {clickIndividualVesselEvent} from '../../../../../core/google-tag-manager/reports/click-individual-vessel-event'

export function numberOfOtherVesselsSelected(
    id: string,
    vesselsSelected: Set<LocationIdType> | undefined,
): number {
    if (!vesselsSelected || vesselsSelected.size === 0) {
        return 0
    }

    return vesselsSelected.has(id) ? vesselsSelected.size - 1 : vesselsSelected.size
}

function determineViewAllState(
    vessels: Array<LocationIdType>,
    selected: Set<LocationIdType> | undefined,
): TriStateButtonState {
    if (!selected) {
        return TriStateButtonState.FULLY_SELECTED
    }

    if (selected.size === 0) {
        return TriStateButtonState.NOT_SELECTED
    }

    const numberOfVessels = vessels?.length || 0

    return numberOfVessels === selected.size
        ? TriStateButtonState.FULLY_SELECTED
        : TriStateButtonState.PARTIAL_SELECTION
}

function isChecked(id: LocationIdType, vesselsSelected: Set<LocationIdType> | undefined): boolean {
    try {
        return !vesselsSelected ? true : vesselsSelected.has(id)
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error({id, vesselsSelected, error})
        return true
    }
}

export function VesselsFilter(): JSX.Element {
    const dispatch = useDispatch()
    const vessels = useTypedSelector(locationsSelector).map((location) => location.location)
    const filter = useTypedSelector(reportsFiltersSelector)

    const vesselsSelected = filter.locations
    const viewAll = determineViewAllState(vessels, vesselsSelected)

    function setChecked(id: LocationIdType, checked: boolean): void {
        dispatch(toggleVessel(vessels, id, checked))
        clickIndividualVesselEvent(checked, numberOfOtherVesselsSelected(id, vesselsSelected))
    }

    function setViewAllState(newState: TriStateButtonState): void {
        if (newState === TriStateButtonState.FULLY_SELECTED) {
            dispatch(toggleAllVessels(true))
        } else if (newState === TriStateButtonState.NOT_SELECTED) {
            dispatch(toggleAllVessels(false))
        }
        clickViewAllVesselsEvent(viewAll, newState)
    }

    return (
        <Styles.Vessels>
            <Styles.VesselsHeadingRow>Vessels</Styles.VesselsHeadingRow>

            <ViewAllVesselsRow state={viewAll} setState={setViewAllState} />
            {vessels.map((id) => {
                return (
                    <VesselRow
                        key={id}
                        locationId={id}
                        checked={isChecked(id, vesselsSelected)}
                        setChecked={setChecked}
                    />
                )
            })}
        </Styles.Vessels>
    )
}
