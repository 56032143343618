import {TriStateButtonState} from './tri-state-button-state'
import checkboxSVG from '../../../@assets/icons/checkmark.svg'
import partialSelectionSVG from '../../../@assets/icons/checkmark-half.svg'
import {Button} from './img-button.styled'

function getImage(type: TriStateButtonState): string {
    switch (type) {
        case TriStateButtonState.FULLY_SELECTED:
            return checkboxSVG
        case TriStateButtonState.PARTIAL_SELECTION:
            return partialSelectionSVG
        default:
            return ''
    }
}

interface Props {
    id: string
    onClick: (value: TriStateButtonState) => void
    state: TriStateButtonState
}
function determineNewState(currentState: TriStateButtonState): TriStateButtonState {
    if (currentState === TriStateButtonState.NOT_SELECTED) {
        return TriStateButtonState.PARTIAL_SELECTION
    }

    return currentState === TriStateButtonState.FULLY_SELECTED
        ? TriStateButtonState.NOT_SELECTED
        : TriStateButtonState.FULLY_SELECTED
}

export function ImgButton({id, onClick, state}: Props): JSX.Element {
    return (
        <Button
            id={id}
            onClick={(e) => {
                e.preventDefault()
                onClick(determineNewState(state))
            }}
        >
            <img src={getImage(state)} />
        </Button>
    )
}
