import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'

interface FooterProps {
    width: number
}

export const Footer = styled.div<FooterProps>`
    border-top: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    padding: ${spacing(2)} ${spacing(4)};
    min-height: 48px;
    display: flex;
    flex-direction: ${(props) => (props.width < 600 ? 'column' : 'row')};
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) => props.theme.colors.background.default};
`
