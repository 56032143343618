import {Feature} from '../../../values/ShipOwnerUIConfig'

export type OAuthClientIdsType = {
    google?: string
    microsoft?: string
}

export enum ConfigFetchingStatus {
    UNINITIALISED = 'UNINITIALISED',
    REQUESTING = 'REQUESTING',
    INITIALISED = 'INITIALISED',
    NEEDS_RESETTING = 'NEEDS_RESETTING',
    REREQUESTING = 'REREQUESTING',
}
export interface ConfigReduxState {
    splunkDashboardUrl?: string
    features?: Feature[]
    trackingId: string | undefined
    auth: string | undefined
    preview: string | undefined
    tenantDisplayName?: string
    showBetaPage?: boolean
    fetchingStatus: ConfigFetchingStatus
    oauthClientIds: OAuthClientIdsType | undefined
}

export const DEFAULT_CONFIG_STATE: ConfigReduxState = {
    features: [],
    trackingId: '',
    auth: '',
    preview: '',
    showBetaPage: false,
    fetchingStatus: ConfigFetchingStatus.UNINITIALISED,
    oauthClientIds: {
        google: '',
        microsoft: '',
    },
}
