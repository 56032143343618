import {CustomerReports} from '../../../../../values/customer-reports'
import {AttachmentButtons} from '../../shared/attachment-button/attachment-buttons'
import {CardDataCell} from '../card-data-cell'

interface ReportsAttachmentsProps {
    report: CustomerReports
}
export function ReportsAttachmentsCard({report}: ReportsAttachmentsProps): JSX.Element {
    return (
        <CardDataCell gridArea="DOCUMENT-TYPE" label="" report={report} identifier="document-type">
            <AttachmentButtons
                identity={report.identity}
                attachments={report.attachments}
                rating={report.rating}
                reportType={report.reportType}
                basalGangliaEnvironmentIdentity={report.basalGangliaEnvironmentIdentity}
            />
        </CardDataCell>
    )
}
