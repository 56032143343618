import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {CustomerReports} from '../../../../values/customer-reports'
import {TableRow} from './table-row'
import {TableDataGridStyle} from './_styled/table-populated.styled'
interface Props {
    customerReports: CustomerReports[]
}
export function ReportsTablePopulated({customerReports}: Props): JSX.Element {
    const {width} = useDimensions()
    return (
        <TableDataGridStyle width={width}>
            {customerReports.map((report, index) => (
                <TableRow key={report.identity} report={report} index={index} />
            ))}
        </TableDataGridStyle>
    )
}
