import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

interface TableHeaderContentProps {
    gridColumn: number
}
export const TableHeaderContent = styled.div<TableHeaderContentProps>`
    grid-column: ${(props) => props.gridColumn};
    display: flex;
    align-self: center;
    column-gap: ${spacing(1)};
    justify-content: flex-start;
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    overflow: hidden;
    white-space: nowrap;
`
