import {ReactNode, ReactNodeArray} from 'react'
import {Navigation} from '../../components/Navigation'
import {FixedPageSizeContainer} from './container.styled'
import {NavigationWrapper} from './navigation-wrapper.styled'

interface Props {
    children: ReactNode | ReactNodeArray
}
export function FixedPageTemplate({children}: Props): JSX.Element {
    return (
        <FixedPageSizeContainer>
            <NavigationWrapper>
                <Navigation />
            </NavigationWrapper>
            {children}
        </FixedPageSizeContainer>
    )
}
