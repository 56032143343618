import ActionType from './action-type'
import {Action} from './actions'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import {
    LocationsReduxState,
    DEFAULT_LOCATIONS_STATE,
    Location,
    LocationMap,
    LocationIdType,
} from './state'
import produce from 'immer'
import {getUpdatedDataArray} from '../reducer-helpers'

function newLocationMap(locations: Location[]): LocationMap {
    const map = new Map<LocationIdType, Location>()
    locations.forEach((location) => map.set(location.location, location))
    return map
}

const LocationsReducer = produce(
    (draft: LocationsReduxState = DEFAULT_LOCATIONS_STATE, action: Action | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.REQUEST_LOCATIONS:
                draft.isFetching = true
                break

            case ActionType.SET_LOCATIONS:
                draft.isFetching = false
                const {dataUpdated, data} = getUpdatedDataArray<Location>(
                    action.payload,
                    draft.locations,
                    (a, b) => a.location === b.location,
                )
                if (dataUpdated) {
                    draft.locations = data
                    draft.locationMap = newLocationMap(data)
                }

                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_LOCATIONS_STATE
                break

            /* istanbul ignore next */
            default:
                break
        }

        return draft
    },
)

export default LocationsReducer
