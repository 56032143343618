import {ReactNode, useReducer} from 'react'
import {PagedReportsContext} from './paged-reports-context'
import {pagedReportsReducer} from './state/reducer'
import {DEFAULT_PAGED_REPORTS_STATE} from './types/default-paged-reports-state'
import {PagedReportsState} from './types/paged-reports-state'

type PagedIncidentsProviderProps = {
    children: ReactNode | ReactNode[]
    pageSize: number
}

export function PagedReportsProvider({
    children,

    pageSize,
}: PagedIncidentsProviderProps): JSX.Element {
    const initialState: PagedReportsState = DEFAULT_PAGED_REPORTS_STATE
    initialState.pageSize = pageSize

    const [state, dispatch] = useReducer(pagedReportsReducer, initialState)

    return (
        <PagedReportsContext.Provider value={{state, dispatch}}>
            {children}
        </PagedReportsContext.Provider>
    )
}
