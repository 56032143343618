import styled from 'styled-components'

interface CardDataCellProps {
    titleOnSameLine?: boolean
    gridArea?: string
}

export const CardDataCell = styled.div<CardDataCellProps>`
    ${(props) => (props.titleOnSameLine ? 'display: flex;' : '')}
    width: 100%;
    align-items: center;
    grid-area: ${(props) => props.gridArea};
`

interface LabelProps {
    width: number
}

export const Label = styled.label<LabelProps>`
    font-size: 12px;
    font-weight: ${(props) => props.theme.font.weight.light};
    line-height: 16px;
    align-items: center;
    min-width: 80px;
    margin: 0;
`
export const Children = styled.div`
    font-size: 12px;
    line-height: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    /* white-space: nowrap; */
    word-break: break-word;
`
