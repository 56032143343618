import {useToggle} from '../../../../../contexts/toggle/use-toggle'

import * as Styles from './filter-bar-header.styled'

export function FilterBarHeader(): JSX.Element {
    const {value: showFilter} = useToggle()

    return (
        <Styles.SectionHeader showFilter={showFilter}>
            <Styles.SectionTitle>Report Filters</Styles.SectionTitle>
        </Styles.SectionHeader>
    )
}
