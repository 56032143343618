import {useDispatch} from 'react-redux'
import {clickIndividualVesselEvent} from '../../../../../core/google-tag-manager/reports/click-individual-vessel-event'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {
    locationMapSelector,
    locationsSelector,
} from '../../../../../store/state/locations/selectors'
import {getLocationDescription, LocationIdType} from '../../../../../store/state/locations/state'
import {setSelectedLocation} from '../../../../../store/state/report-filters/action-creators'
import {reportsFiltersSelector} from '../../../../../store/state/report-filters/selectors'
import {getFormattedSelectedVessels} from '../../data-helpers'
import {numberOfOtherVesselsSelected} from './vessels-filter'
import * as Styles from './vessels-filter.styled'
export function getLocationIdSet(locationId: LocationIdType): Set<LocationIdType> {
    const newLocationsSet = new Set<LocationIdType>()
    newLocationsSet.add(locationId)
    return newLocationsSet
}
export function VesselsFilterNarrow(): JSX.Element | null {
    const dispatch = useDispatch()
    const vesselsIds = useTypedSelector(locationsSelector).map((location) => location.location)
    const vesselsFiltered = useTypedSelector(reportsFiltersSelector).locations

    const selectedVessel = getFormattedSelectedVessels(vesselsFiltered)

    const locationMap = useTypedSelector(locationMapSelector)

    function onChangeWrapper(event: React.ChangeEvent<HTMLSelectElement>): void {
        event.preventDefault()
        dispatch(setSelectedLocation(getLocationIdSet(event.target.value)))
        clickIndividualVesselEvent(
            true,
            numberOfOtherVesselsSelected(event.target.value, vesselsFiltered),
        )
    }

    if (vesselsIds?.length === 0) {
        return null
    }
    return (
        <Styles.VesselNarrow>
            <Styles.Label>Vessels</Styles.Label>
            <Styles.Input>
                <Styles.Select onChange={onChangeWrapper} value={selectedVessel}>
                    <option key="default-option" value="">
                        - All -
                    </option>
                    {vesselsIds.map((value) => (
                        <option key={value} value={value}>
                            {getLocationDescription(locationMap, value)}
                        </option>
                    ))}
                </Styles.Select>
            </Styles.Input>
        </Styles.VesselNarrow>
    )
}
