import {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'

import {ThemeProvider} from 'styled-components'
import {LightTheme} from '../theme/light-theme'
import {DarkTheme} from '../theme/dark-theme'

import {configSelector} from '../store/state/config/selectors'
import RoleChecker from '../components/RoleChecker'
import useTypedSelector from '../hooks/use-typed-selector'
import {logInStatusSelector} from '../store/state/session-data/selectors'
import {DimensionsProvider} from '../contexts/dimensions/dimensions-provider'
import {setupTracking} from '../core/google-tag-manager/setup-tracking'
import {AuthenticatedRoutes} from './authenticated-routes'
import {UnauthenticatedRoutes} from './unauthenticated-routes'
import {useAuthenticatedSemaphore} from '../hooks/use-authenticated-semaphore'
import {LoginStatus} from '../store/state/session-data/state'
import {useDispatch} from 'react-redux'
import {loggingOut} from '../store/state/session-data/action-creators'
import {currentUserSelector} from '../store/state/current-user/selectors'
import {v4 as uuid} from 'uuid'
import {getGtmUserIdentifier} from '../core/google-tag-manager/helpers/gtm-user-identifier'

const App = (): JSX.Element => {
    const dispatch = useDispatch()
    const config = useTypedSelector(configSelector)
    const loginStatus = useTypedSelector(logInStatusSelector)
    const {authenticationSemaphoreStatus} = useAuthenticatedSemaphore()

    const gtmIdentifer = getGtmUserIdentifier(
        useTypedSelector(currentUserSelector),
        useState(uuid())[0],
    )

    useEffect(() => {
        setupTracking(config.trackingId, config.auth, config.preview, gtmIdentifer)
    }, [config.trackingId, config.auth, config.preview, gtmIdentifer])

    const title: string = config.tenantDisplayName
        ? `${config.tenantDisplayName} - Medulla`
        : 'Medulla'

    const darkMode = false

    useEffect(() => {
        if (authenticationSemaphoreStatus) {
            // do nothing for now
        } else {
            if (
                loginStatus == null ||
                loginStatus == undefined ||
                loginStatus === LoginStatus.LOGGED_IN
            ) {
                dispatch(loggingOut())
            }
        }
    }, [authenticationSemaphoreStatus, dispatch, loginStatus])

    return (
        <DimensionsProvider>
            <ThemeProvider theme={darkMode ? DarkTheme : LightTheme}>
                <RoleChecker rolesAllowed={['INTERNAL']}>
                    <Helmet>
                        <title>{title}</title>
                    </Helmet>
                </RoleChecker>
                {loginStatus === LoginStatus.LOGGED_IN ? (
                    <AuthenticatedRoutes />
                ) : (
                    <UnauthenticatedRoutes />
                )}
            </ThemeProvider>
        </DimensionsProvider>
    )
}

export default App
