import {usePagedReports} from '../../contexts/use-paged-reports'
import {NoDataTable} from './no-data-table/no-data-table'
import {ReportsTablePopulated} from './repors-table-populated'

export function ReportsTableLoaded(): JSX.Element {
    const {totalNumberOfReports, dataReports} = usePagedReports()

    return dataReports && totalNumberOfReports && totalNumberOfReports > 0 ? (
        <ReportsTablePopulated customerReports={dataReports} />
    ) : (
        <NoDataTable />
    )
}
