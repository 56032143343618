import {MouseEvent} from 'react'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import * as Styles from './button.styled'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {narrowFilterLayout} from '../data-helpers'
import {usePagedReports} from '../../contexts/use-paged-reports'
import {batch, useDispatch} from 'react-redux'
import {fetchLocations} from '../../../../store/state/locations/action-creators'
import {fetchCurrentUser} from '../../../../store/state/current-user/action-creators'
import {refreshDataEvent} from '../../../../core/google-tag-manager/reports/refresh-data-event'

export function RefreshButton(): JSX.Element {
    const {width} = useDimensions()
    const dispatch = useDispatch()

    const {refreshData} = usePagedReports()
    function refreshFunction(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        refreshDataEvent()
        refreshData()
        batch(() => {
            dispatch(fetchLocations())
            dispatch(fetchCurrentUser())
        })
    }

    return (
        <Styles.Button id="refresh-button" onClick={(e) => refreshFunction(e)}>
            <Icon glyph="Reset" height={16} width={16} />
            <Styles.Text>{narrowFilterLayout(width) ? 'Refresh Data' : 'Refresh'}</Styles.Text>
        </Styles.Button>
    )
}
