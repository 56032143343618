import {StarIcon} from './star-icon'

interface StarRatingProps {
    rating: number
}

export function StarRating({rating}: StarRatingProps): JSX.Element | null {
    if (rating === 0) {
        return null
    }
    return (
        <>
            {[1, 10, 33, 66, 90].map((percentageThreshold) => (
                <StarIcon
                    key={percentageThreshold}
                    percentageThreshold={percentageThreshold}
                    rating={rating}
                />
            ))}
        </>
    )
}
