import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

function narrowLayout(width: number): boolean {
    return width < 1000
}
function getGridTemplate(width: number): string {
    if (narrowLayout(width)) {
        return '"SHIP-MANAGER VESSEL" "START-DATE END-DATE" "REPORT-NOTES DOCUMENT-TYPE"'
    }
    return "'SHIP-MANAGER SHIP-MANAGER START-DATE START-DATE DOCUMENT-TYPE' 'VESSEL VESSEL END-DATE END-DATE REPORT-NOTES'"
}

function getColumnTemplate(width: number): string {
    if (narrowLayout(width)) {
        return 'repeat(2, 1fr);'
    }
    return 'repeat(5, 1fr);'
}
export const CardBorder = styled.div`
    border-left: thick solid ${(props) => props.theme.reports.dataArea.borderLeft};
`
export const Card = styled.div`
    color: ${(props) => props.theme.reports.dataRow.text};
    background-color: ${(props) => props.theme.reports.dataRow.background};
    margin-bottom: ${spacing(2)};
    box-shadow: 1px 1px 6px -2px ${(props) => props.theme.reports.dataArea.boxShadow};
    ${smallFont()};
`
interface CardAreaProps {
    width: number
}
export const CardAreaContent = styled.div<CardAreaProps>`
    display: grid;
    grid-template-columns: ${(props) => getColumnTemplate(props.width)};
    grid-template-areas: ${(props) => getGridTemplate(props.width)};
    row-gap: ${spacing(2)};
    padding: ${spacing(2)};
    overflow: hidden;
`
export const CardAreaTitle = styled.div<CardAreaProps>`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas: 'REPORTID REPORTID REPORTTYPE REPORTTYPE REPORTTYPE';
    padding: ${spacing(2)} ${spacing(2)} 0 ${spacing(2)};
`
interface CardTitleProps {
    gridArea: string
}
export const CardReportCodeTitleData = styled.h2<CardTitleProps>`
    font-size: 14px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    line-height: 21px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
    margin: 0;
    grid-area: ${(props) => props.gridArea};
`
export const CardReportTypeTitleData = styled.div<CardTitleProps>`
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    line-height: 21px;
    grid-area: ${(props) => props.gridArea};
`
