import styled from 'styled-components'

export const PrimaryButton = styled.button`
    padding: 7.5px;
    width: 100%;
    color: #2e3a46;
    background-color: ${(props) => props.theme.colors.background.whiteBackground};
    border: none;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: 0.4px;
    font-weight: 600;
    border-radius: 6px;
`
