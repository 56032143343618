import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {getGridTemplateColumns} from '../../data-helpers'

export const ReportsTable = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    padding-top: ${spacing(6)};
`
interface Props {
    width: number
}
export const TableDataGridStyle = styled.div<Props>`
    display: grid;
    grid-template-columns: ${(props) => getGridTemplateColumns(props.width)};
    gap: ${spacing(2)};
    padding: 0 ${spacing(4)} ${spacing(2)} ${spacing(4)};
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
    background-color: ${(props) => props.theme.reports.dataArea.background};
`
