import ActionType from './action-type'
import * as Actions from './actions'
import {Api} from '../../../api/Api'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'
import {REST} from '../../..'
import {UserData} from '../users/state'

const CURRENT_USER_REST_URL = '/api/v1/users/current'

export function fetchCurrentUser(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch) => {
        dispatch(requestCurrentUserAction())

        REST.get(CURRENT_USER_REST_URL).then((response) => {
            dispatch(receiveCurrentUserAction(response.data))
        })
    }
}

function requestCurrentUserAction(): Actions.RequestCurrentUserAction {
    return {
        type: ActionType.REQUEST_CURRENT_USER,
    }
}

function receiveCurrentUserAction(currentUser: UserData): Actions.ReceiveCurrentUserAction {
    return {
        type: ActionType.RECEIVE_CURRENT_USER,
        payload: currentUser,
    }
}
