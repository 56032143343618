export function getAttachmentType(contentType: string): 'excel' | 'csv' | 'pdf' | 'misc' | null {
    if (contentType == undefined || contentType == null) {
        return null
    }
    if (contentType.toLowerCase().startsWith('application/vnd')) {
        return 'excel'
    }
    if (contentType.toLowerCase() === 'application/csv') {
        return 'csv'
    }
    if (contentType.toLowerCase() === 'application/pdf') {
        return 'pdf'
    }
    return 'misc'
}
