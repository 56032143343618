import {CheckboxType} from './checkbox-type'
import * as Styles from './checkbox.styles'

interface Props {
    id: string
    checked: boolean
    onChange: (value: boolean) => void
    label: string
    type?: CheckboxType
}
export function Checkbox({
    id,
    checked,
    onChange,
    label,
    type = CheckboxType.DEFAULT,
}: Props): JSX.Element {
    return (
        <Styles.Label htmlFor={id}>
            <Styles.CheckboxInput
                type="checkbox"
                id={id}
                onChange={() => onChange(!checked)}
                checked={checked}
                checkboxType={type}
            />
            <Styles.StyledCheckbox />
            <Styles.Text>{label}</Styles.Text>
        </Styles.Label>
    )
}
