import {ConfigFetchingStatus, ConfigReduxState, OAuthClientIdsType} from './state'
import AppState from '../../types/app-state'

export const configSelector = (state: AppState): ConfigReduxState => state.config

export const showBetaPageSelector = (state: AppState): boolean | undefined =>
    state.config.showBetaPage

export const fetchingStatusSelector = (state: AppState): ConfigFetchingStatus =>
    state.config.fetchingStatus
export const fetchingStatusDataIsLoadedSelector = (state: AppState): boolean =>
    state.config.fetchingStatus === ConfigFetchingStatus.INITIALISED
export const oauthClientIdsSelector = (state: AppState): OAuthClientIdsType | undefined =>
    state.config.oauthClientIds
