import * as Styles from './_styles/logo.styled'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'

export function Logo(): JSX.Element {
    return (
        <Styles.Logo>
            <Icon glyph="Medulla" />
        </Styles.Logo>
    )
}
