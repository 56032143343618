import {useDispatch} from 'react-redux'
import {selectReportTypeEvent} from '../../../../../core/google-tag-manager/reports/select-report-type-event'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {setSelectedReportType} from '../../../../../store/state/report-filters/action-creators'
import {reportsFilterReportTypeSelector} from '../../../../../store/state/report-filters/selectors'
import {usePagedReports} from '../../../contexts/use-paged-reports'
import * as Styles from './report-type.styled'

export function ReportType(): JSX.Element {
    const dispatch = useDispatch()
    const {allReportTypes} = usePagedReports()
    const selectedReportType = useTypedSelector(reportsFilterReportTypeSelector)
    function onChangeWrapper(event: React.ChangeEvent<HTMLSelectElement>): void {
        event.preventDefault()
        selectReportTypeEvent(selectedReportType, event.currentTarget.value)
        dispatch(setSelectedReportType(event.target.value))
    }
    const filteredAllReportType = Array.from(new Set<string>(allReportTypes))

    return (
        <Styles.Reports>
            <Styles.Label>Report Type:</Styles.Label>

            <Styles.Input>
                <Styles.Select onChange={onChangeWrapper} value={selectedReportType}>
                    <option key="default-option" value="">
                        - All -
                    </option>
                    {filteredAllReportType?.map((value) => (
                        <option key={value} value={value}>
                            {value}
                        </option>
                    ))}
                </Styles.Select>
            </Styles.Input>
        </Styles.Reports>
    )
}
