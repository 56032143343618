import {useState} from 'react'
import {getRatingBoundarybuttons} from '../../data-helpers'

import * as Styles from './compliance-rating.styled'
import {RatingButton} from './rating-button'

export function ComplianceRating(): JSX.Element {
    const [previewSelectedRating, setPreviewSelectedRating] = useState<number | null>(null)

    function stopSelecting(): void {
        if (previewSelectedRating) {
            setPreviewSelectedRating(null)
        }
    }
    function mouseOverStar(value: number): void {
        setPreviewSelectedRating(value)
    }

    return (
        <Styles.ComplianceContent>
            <Styles.Label>Compliance Rating</Styles.Label>
            <Styles.StarsBox id="compliance-rating-filter-bar" onMouseLeave={stopSelecting}>
                {getRatingBoundarybuttons().map((percentageThreshold) => (
                    <RatingButton
                        key={`button-${percentageThreshold}`}
                        percentageThreshold={percentageThreshold}
                        previewSelectedRating={previewSelectedRating}
                        mouseOverStar={mouseOverStar}
                        stopSelecting={stopSelecting}
                    />
                ))}
            </Styles.StarsBox>
        </Styles.ComplianceContent>
    )
}
