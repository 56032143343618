import {Button} from './empty-button.styled'
import {TriStateButtonState} from './tri-state-button-state'

interface Props {
    id: string
    onClick: (value: TriStateButtonState) => void
}

export function EmptyButton({id, onClick}: Props): JSX.Element {
    return (
        <Button
            id={id}
            onClick={(e) => {
                e.preventDefault()
                onClick(TriStateButtonState.FULLY_SELECTED)
            }}
        ></Button>
    )
}
