import {Location, LocationMap} from './state'
import AppState from '../../types/app-state'

export const locationsLoadingSelector = (state: AppState): boolean => state.locations.isFetching

export const locationsSelector = (state: AppState): Location[] => state.locations.locations

export const locationMapSelector = (state: AppState): LocationMap => state.locations.locationMap

export const hasLocationsSelector = (state: AppState): boolean =>
    state.locations.locations?.length > 0 || false
