import {GuidType} from '../../../values/generic-type-defintions'
import LoadingState from '../../../values/loading-state-enum'
import {LocationIdType} from '../locations/state'

export interface ReportsFiltersReduxState {
    loadingState: LoadingState
    locations: Set<LocationIdType> | undefined
    selectedRating: number
    selectedFromTimeRange: string | undefined
    selectedToTimeRange: string | undefined
    allReportTypes: string[] | undefined
    selectedReportType: string | undefined
    selectedBasalGangliaEnvs: GuidType | undefined
    includeWholeFleetReports: boolean
}

export const DEFAULT_REPORTS_FILTERS_STATE: ReportsFiltersReduxState = {
    loadingState: LoadingState.NotPopulated,
    locations: undefined,
    selectedRating: 101,
    selectedFromTimeRange: '',
    selectedToTimeRange: '',
    allReportTypes: undefined,
    selectedReportType: '',
    selectedBasalGangliaEnvs: '',
    includeWholeFleetReports: true,
}
