import {animated, useSpring} from 'react-spring'
import {Container} from './container.styled'
import {Segment} from './segment'

interface LoadingSpinnerProps {
    size?: number
}
export function LoadingSpinner({size = 80}: LoadingSpinnerProps): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fadeStyles: any = useSpring({
        from: {opacity: 0},
        to: {
            opacity: 1,
        },
        delay: 250,
    })

    return (
        <animated.div style={fadeStyles}>
            <Container size={size}>
                <Segment
                    segmentSize={size / 2}
                    color="#dcdcdc"
                    animationDuration="0.5555555555555556s"
                />

                <Segment
                    segmentSize={size / 2}
                    color="#bdbdbd"
                    animationDuration="0.7407407407407407s"
                />
                <Segment
                    segmentSize={size / 2}
                    color="#9a9a9a"
                    animationDuration="1.1111111111111112s"
                />
                <Segment
                    segmentSize={size / 2}
                    color="#fe4e30"
                    animationDuration=" 2.2222222222222223s"
                />
            </Container>
        </animated.div>
    )
}
