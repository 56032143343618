import {TriStateButton} from '../../../../../components/form-elements/tri-state-button/tri-state-button'
import {TriStateButtonState} from '../../../../../components/form-elements/tri-state-button/tri-state-button-state'

interface Props {
    state: TriStateButtonState
    setState: (value: TriStateButtonState) => void
}

export function ViewAllVesselsRow({state, setState}: Props): JSX.Element {
    return (
        <div>
            <TriStateButton
                id="asset-filter-viewall"
                label="View All"
                state={state}
                onClick={setState}
            />
        </div>
    )
}
