import {PrimaryButton} from './styles/primary-button.styled'
import {loggingOut} from '../../../../store/state/session-data/action-creators'
import {useDispatch} from 'react-redux'

interface SignOutButtonProps {
    buttonText?: string
}

export function SignOutButton({buttonText = 'Log Out'}: SignOutButtonProps): JSX.Element {
    const dispatch = useDispatch()
    return <PrimaryButton onClick={() => dispatch(loggingOut())}>{buttonText}</PrimaryButton>
}
