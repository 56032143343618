import ActionType from './action-type'
import {Action} from './actions'

import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'

import {
    BasalGangliaData,
    BasalGangliaDataMap,
    BasalGangliaEnvReduxState,
    DEFAULT_BASAL_GANGLIA_ENV_STATE,
} from './state'
import produce from 'immer'

import {getUpdatedDataArray} from '../reducer-helpers'
import {GuidType} from '../../../values/generic-type-defintions'
function newBasalGangliaEnvMap(basalGangliaEnvMap: BasalGangliaData[]): BasalGangliaDataMap {
    const map = new Map<GuidType, BasalGangliaData>()
    basalGangliaEnvMap.forEach((basalGanglia) => map.set(basalGanglia.Identity, basalGanglia))
    return map
}
const basalGangliaEnvReducer = produce(
    (
        draft: BasalGangliaEnvReduxState = DEFAULT_BASAL_GANGLIA_ENV_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_BASAL_GANGLIA_ENV:
                draft.isFetching = true
                break
            case ActionType.RECEIVE_BASAL_GANGLIA_ENV:
                draft.isFetching = false
                const {dataUpdated, data} = getUpdatedDataArray<BasalGangliaData>(
                    action.payload,
                    draft.basalGangliaEnv,
                    (a, b) => a.Identity === b.Identity,
                )
                if (dataUpdated) {
                    draft.basalGangliaEnv = data
                    draft.basalGangliaEnvMap = newBasalGangliaEnvMap(data)
                }
                break
            case ActionType.SET_ERROR:
                draft.isFetching = false
                draft.error = action.payload
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_BASAL_GANGLIA_ENV_STATE
                break
            /* istanbul ignore next */
            default:
                return draft
        }
        return draft
    },
)
export default basalGangliaEnvReducer
