import {useDispatch} from 'react-redux'
import {selectShipManagerEvent} from '../../../../../core/google-tag-manager/reports/select-ship-manager-event'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {basalGangliaEnvSelector} from '../../../../../store/state/basal-ganglia-env/selectors'
import {setSelectedBasalGangliaEnv} from '../../../../../store/state/report-filters/action-creators'
import {reportsFilterBasalGangliaEnvsSelector} from '../../../../../store/state/report-filters/selectors'
import * as Styles from './ship-manager.styled'

export function ShipManager(): JSX.Element {
    const dispatch = useDispatch()

    function onChangeWrapper(event: React.ChangeEvent<HTMLSelectElement>): void {
        event.preventDefault()
        selectShipManagerEvent(selectedValue, event.target.value)
        dispatch(setSelectedBasalGangliaEnv(event.target.value))
    }
    const allManagerNames = useTypedSelector(basalGangliaEnvSelector)
    const selectedValue = useTypedSelector(reportsFilterBasalGangliaEnvsSelector)

    return (
        <Styles.List>
            <Styles.Label>Ship Manager:</Styles.Label>

            <Styles.Input>
                <Styles.Select onChange={onChangeWrapper} value={selectedValue}>
                    <option key="default-option" value="">
                        - All -
                    </option>
                    {allManagerNames?.map((value) => (
                        <option key={value.Identity} value={value.Identity}>
                            {value.Name}
                        </option>
                    ))}
                </Styles.Select>
            </Styles.Input>
        </Styles.List>
    )
}
