import ActionType from './action-type'
import * as Actions from './actions'
import {Api} from '../../../api/Api'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'
import {REST} from '../../..'
import {BasalGangliaData} from './state'

const BASAL_GANGLIA_ENV_REST_URL = '/api/v1/basalGangliaEnvironments'

export function fetchBasalGangliaEnv(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch) => {
        dispatch(requestBasalGangliaEnvAction())

        REST.get(BASAL_GANGLIA_ENV_REST_URL)
            .then((response) => {
                dispatch(receiveBasalGangliaEnvAction(response.data))
            })
            .catch((err) => dispatch(setError(err)))
    }
}

function requestBasalGangliaEnvAction(): Actions.RequestBasalGangliaEnvAction {
    return {
        type: ActionType.REQUEST_BASAL_GANGLIA_ENV,
    }
}

function receiveBasalGangliaEnvAction(
    basalGangliaEnv: BasalGangliaData[],
): Actions.ReceiveBasalGangliaEnvAction {
    return {
        type: ActionType.RECEIVE_BASAL_GANGLIA_ENV,
        payload: basalGangliaEnv,
    }
}
function setError(error: string): Actions.SetError {
    return {
        type: ActionType.SET_ERROR,
        payload: error,
    }
}
