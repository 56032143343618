import {EmptyButton} from './empty-button'
import {ImgButton} from './img-button'
import {TriStateButtonState} from './tri-state-button-state'
import * as Styles from './tri-state-button.styled'

interface Props {
    id: string
    state: TriStateButtonState
    onClick: (value: TriStateButtonState) => void
    label: string
}

export function TriStateButton({id, state, onClick, label}: Props): JSX.Element {
    return (
        <Styles.Label htmlFor={id}>
            {state === TriStateButtonState.NOT_SELECTED ? (
                <EmptyButton id={id} onClick={onClick} />
            ) : (
                <ImgButton id={id} onClick={onClick} state={state} />
            )}
            <Styles.Text missingLabel={label.length === 0}>{label}</Styles.Text>
        </Styles.Label>
    )
}
