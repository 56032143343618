import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {ByText} from './styles/by-text.styled'
import * as HeaderLayout from './styles/header-layout.styled'
import {Medulla} from './medulla'
import {OwlLogo} from './owl-logo'
import {ShipOwners} from './ship-owners'
import {getScale} from './styles/header-items-dimensions'
export enum HeaderSize {
    SMALL = 0,
    LARGE = 1,
}
interface HeaderProps {
    headerSize: HeaderSize
}

export function Header({headerSize}: HeaderProps): JSX.Element {
    const {width} = useDimensions()

    return (
        <HeaderLayout.CssGrid headerSize={headerSize === HeaderSize.SMALL}>
            <HeaderLayout.LogoCell>
                <OwlLogo scale={getScale(width)} />
            </HeaderLayout.LogoCell>
            <HeaderLayout.NameCell>
                <Medulla scale={getScale(width)} />
            </HeaderLayout.NameCell>
            <HeaderLayout.DomainCell>
                <ShipOwners scale={getScale(width)} />
            </HeaderLayout.DomainCell>
            <HeaderLayout.ByTextCell>
                <ByText>By Cyberowl</ByText>
            </HeaderLayout.ByTextCell>
        </HeaderLayout.CssGrid>
    )
}
