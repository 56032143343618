import {useEffect} from 'react'
import * as Styles from './reports.styled'
import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import {viewPageEvent} from '../../core/google-tag-manager/reports/view-page-event'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {ToggleProvider} from '../../contexts/toggle/toggle-provider'
import {PagedReportsProvider} from './contexts/paged-reports-provider'
import {useToggle} from '../../contexts/toggle/use-toggle'
import {showFilterBarOnScreen} from './components/data-helpers'
import {ScrollablePageTemplate} from '../../templates/scrollable-page/scrollable-page-template'
import {FilterBarOnScreen} from './components/filter-bar/filter-bar'
import {Content} from './content'
import {FilterBarNarrow} from './components/filter-bar/filter-bar-narrow'
import {CloseFilterButton} from './components/filter-bar/close-filter/close-filter-button'

export function MedullaReports(): JSX.Element {
    useEffect(() => {
        viewPageEvent()
    }, [])

    return (
        <ToggleProvider>
            <PagedReportsProvider pageSize={10}>
                <ReportsPage />
            </PagedReportsProvider>
        </ToggleProvider>
    )
}
function ReportsPage(): JSX.Element {
    const {width} = useDimensions()
    const {value: showFilter, setValue: setShowFilter} = useToggle()
    const showFilterBar = showFilterBarOnScreen(width)

    useEffect(() => {
        if (showFilter) {
            if (showFilterBar) {
                setShowFilter(false)
            }
        }
    }, [setShowFilter, showFilter, showFilterBar])

    if (!showFilterBar && showFilter) {
        return (
            <ScrollablePageTemplate onClick={() => setShowFilter(false)}>
                <Styles.FilterBarWrapper width={width} onClick={(e) => e.stopPropagation()}>
                    <Styles.TopCloseFilterBar>
                        <CloseFilterButton />
                    </Styles.TopCloseFilterBar>
                    <FilterBarNarrow />
                    <Styles.BottomCloseFilterBar>
                        <CloseFilterButton />
                    </Styles.BottomCloseFilterBar>
                </Styles.FilterBarWrapper>
            </ScrollablePageTemplate>
        )
    } else {
        return (
            <FixedPageTemplate>
                <Styles.WideContentArea width={width} fullHeight={!showFilterBar}>
                    {showFilterBar && (
                        <Styles.LHFilterBar>
                            <FilterBarOnScreen />
                        </Styles.LHFilterBar>
                    )}
                    <Content />
                </Styles.WideContentArea>
            </FixedPageTemplate>
        )
    }
}
