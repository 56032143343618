import LoadingState from '../../../../values/loading-state-enum'
import {usePagedReports} from '../../contexts/use-paged-reports'
import {LoadingData} from '../../loading-data'

import {ReportsCardsLoaded} from './reports-cards-loaded'
export function ReportsCardsContent(): JSX.Element {
    const {loading, totalNumberOfReports} = usePagedReports()

    const showLoading =
        totalNumberOfReports == undefined ||
        loading === LoadingState.NotPopulated ||
        loading === LoadingState.RequestingData

    if (showLoading) {
        return <LoadingData />
    }
    return <ReportsCardsLoaded />
}
